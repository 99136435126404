const translations = {
  prefacio_posfacio: 'Prefácio / Posfácio',
  outra_producao_bibliografica: 'Outra prod. bibl.',
  livro: 'Livro',
  artigo: 'Artigo',
  evento: 'Evento',
  traducao: 'Tradução',
  jornal: 'Jornal',
  capitulo: 'Capítulo',
  maquete: 'Maquete',
  patente: 'Patente',
  editoracao: 'Editoração',
  marca: 'Marca',
  processos_ou_tecnicas: 'Processos ou Técnicas',
  software: 'Software',
  carta_mapa_ou_similar: 'Carta, mapa ou similar',
  manutencao_de_obra_artistica: 'Manutenção de obra artística',
  curso_de_curta_duracao_ministrado: 'Curso de curta duração ministrado',
  apresentacao_de_trabalho: 'Apresentação de trabalho',
  programa_de_radio_ou_tv: 'Programa de rádio ou TV',
  desenho_industrial: 'Desenho industrial',
  relatorio_de_pesquisa: 'Relatório de pesquisa',
  desenvolvimento_de_material_didatico_ou_instrucional: 'Desenvolvimento de material didático ou instrucional',
  produto_tecnologico: 'Produto tecnológico',
  outra_producao_tecnica: 'Outra produção técnica',
  trabalho_tecnico: 'Trabalho técnico',
  midia_social_website_blog: 'Mídia social, website ou blog',
  organizacao_de_evento: 'Organização de evento',
  artes_cenicas: 'Artes cênicas',
  artes_visuais: 'Artes visuais',
  curso_de_curta_duracao: 'Curso de curta duração',
  musica: 'Música',
  obra_de_artes_visuais: 'Obra de artes visuais',
  outra_producao_artistica_cultural: 'Outra produção artística ou cultural',
  avaliacao_cursos: 'Avaliação de cursos',
  aperfeicoamento_especializacao: 'Aperfeiçoamento e Especialização',
  professor_titular: 'Professor Titular',
  graduacao: 'Graduação',
  outra: 'Outra',
  livre_docencia: 'Livre docência',
  mestrado: 'Mestrado',
  exame_qualificacao: 'Exame de qualificação',
  doutorado: 'Doutorado',
  concurso_publico: 'Concurso público',
  trabalho_de_conclusao_de_curso_graduacao: 'Trabalho de conclusão de curso Graduação',
  monografia_de_conclusao_de_curso_aperfeicoamento_e_especializacao: 'Monografia de conclusão de curso',
  iniciacao_cientifica: 'Iniciação Científica',
  orientacao_de_outra_natureza: 'Orientação de outra natureza',
  doutorado_andamento: 'Doutorado em andamento',
  iniciacao_cientifica_andamento: 'Iniciação científica em andamento',
  mestrado_andamento: 'Mestrado em andamento',
  pos_doutorado: 'Pós-doutorado',
  pos_doutorado_andamento: 'Pós-doutorado em andamento',
};

const dict = (str) => {
  return translations[str] || str;
};

const inv = (str) => {
  return Object.keys(translations).find((key) => {
    return translations[key] === str;
  });
};

export {
  dict,
  inv,
};
