import React from 'react';
import { setColorDocentesAUP } from '../components/btnFunctions';
import DocentesList from '../components/DocentesList';

function DocentesAUP() {
  return (
    <DocentesList
      department="AUP"
      setColorDocentes={setColorDocentesAUP}
    />
  );
}

export default DocentesAUP;
