import React, { useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { setColorDepAUT } from '../components/btnFunctions';
import {
  getDashboardCount, getDashboardCountByDep, getDashboard,
  getDashboardByDep, getDashboardKeywords, getDashboardMap,
  getDashboardMapNational,
} from '../api';
import {
  Conteudo, DivCard, DivInfo, DivGraph, DivInfoText, DivDep, DivInfoProf, departmentColors,
} from '../styles';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import LineChartDepartamento from '../components/chart-line-departamento';
import BarChartDepartamento from '../components/chart-bar-departamento';
import KeywordsDepartamento from '../components/keywords-departamento';
import MapDepartamento from '../components/map-departamento';
import MapDepartamentoNacional from '../components/map-departamento-nacional';

function AUT() {
  useEffect(() => {
    setColorDepAUT();
  }, []);

  return (
    <Conteudo style={{ justifyContent: 'start' }}>
      <DivCard>
        <DivInfo>
          <DivDep
            style={{
              backgroundColor: departmentColors.AUT,
              color: '#fff',
            }}
          >
            AUT
          </DivDep>
          <DivInfoProf>
            <span
              style={{
                fontSize: 25,
                fontWeight: 'bold',
              }}
            >
              Departamento de Tecnologia da Arquitetura
            </span>
            <span>Chefe</span>
            <span>Profa. Dra. Maria Camila Loffredo D’Ottaviano</span>
            <span>Vice-Chefe</span>
            <span>Prof. Dr. Artur Simões Rozestraten</span>
          </DivInfoProf>
        </DivInfo>
        <DivGraph>
          <Carousel
            showArrows
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
            autoPlay
            infiniteLoop
            interval={5000}
          >
            <div>
              <KeywordsDepartamento
                dep="AUT"
                getKeywords={getDashboardKeywords}
              />
            </div>
            <div>
              <MapDepartamento
                dep="AUT"
                getMap={getDashboardMap}
              />
            </div>
            <div>
              <MapDepartamentoNacional
                dep="AUT"
                getMap={getDashboardMapNational}
              />
            </div>
            <div>
              <BarChartDepartamento
                dep="AUT"
                getCountDashboard={getDashboard}
                getCountByDep={getDashboardByDep}
              />
            </div>
            <div>
              <LineChartDepartamento
                dep="AUT"
                getDashboardCount={getDashboardCount}
                getDepCount={getDashboardCountByDep}
              />
            </div>
          </Carousel>
        </DivGraph>
        <DivInfoText>
          <span
            style={{
              fontSize: 25,
              fontWeight: 'bold',
            }}
          >
            Histórico
          </span>
          <span>
            O Departamento de Tecnologia da Arquitetura (AUT) foi constituídoem
            18 de novembro de 1964, tendo como primeiro chefe o Prof. Dr. José
            Carlos Figueiredo Ferraz, fazendo também parte do colegiado os professores
            Telêmaco Hipólito de Macedo van Langendonck, Ariosto Mila, Guilherme
            do Amaral Lyra, Paulo Sampaio Wilken, Léo Quanji Nishikawa, John Manoel
            de Souza, Aluísio Fontana Margarido, José de Ribamar e Silva e o
            representante do Grêmio da FAU, o aluno Carlos Eduardo Zahn, conforme
            primeira ata de reunião do departamento, de 18.11.1964.
          </span>
          <span>
            O Departamento está estruturado para operar, desde 2011, com 34 docentes
            em seu quadro, momento este em que o Curso de Design formou sua primeira
            turma e os dois cursos passaram a atuar de forma plena. No entanto,
            o AUT conta, atualmente, com um corpo docente de somente 25 professores
            efetivos e 2 temporários, alocados nos dois Cursos de graduação, em
            Arquitetura e Urbanismo e em Design. O Departamento está estruturado,
            na graduação, em três grupos temáticos de disciplinas, a saber: a&#41;
            Construção, b&#41; Conforto Ambiental e c&#41; Metodologia. Os docentes, em
            geral, concentram suas atividades de docência dentro de um destes grupos
            temáticos, porém, tem-se estimulado, mais recentemente, a participação
            dos docentes em atividades que ultrapassam os seus grupos de origem.
          </span>
          <span>
            O Departamento abriga 53 disciplinas de graduação do Curso de Arquitetura
            e Urbanismo, bem como 20 disciplinas de graduação do Curso de Design,
            entre obrigatórias e optativas, incluindo as disciplinas ministradas
            por 30 docentes de diversos Departamentos da Escola Politécnica e do
            Instituto de Matemática e Estatística da USP. Além disso, docentes
            deste Departamento são responsáveis por 21 disciplinas de Pós-Graduação
            da FAUUSP, nos Programas em Arquitetura e Urbanismo ou em Design da
            mesma Faculdade, sendo que 19 docentes estão atualmente credenciados
            para orientação de mestrado e/ou doutorado destes programas. Além disso,
            2 docentes deste Departamento também participam de programas de
            pós-graduação de outras unidades da USP.
          </span>
          <span>
            O corpo docente deste Departamento é responsável e atua em quatro
            laboratórios de pesquisa da FAUUSP, vinculados a esse Departamento:
            o LABAUT – Laboratório de Conforto Ambiental e Eficiência Energética,
            criado em 2001, o LCC – Laboratório de Culturas Construtivas, criado
            em 2012, o RELAB – Laboratório de Representações da Arquitetura e do
            Urbanismo, criado em 2015 e o LABMAT – Laboratório de Materiais da
            Faculdade de Arquitetura e Urbanismo, criado em 2018. Os docentes deste
            Departamento também participam de núcleos de apoio à pesquisa subordinados
            à Pró-Reitoria de Pesquisa da USP, como o NUTAU – Núcleo de Pesquisa em
            Tecnologia da Arquitetura e Urbanismo, o NAPPLAC – Núcleo de Apoio a
            Pesquisa, Produção e Linguagem do Ambiente, o USP-CIDADES, o NAWEB –
            Núcleo de Apoio à Pesquisa em Ambientes Colaborativos na Web e o INCLINE
            – Núcleo de Apoio à Pesquisa em Mudanças Climáticas.
          </span>
          <span>
            Docentes do Departamento também atuam em duas seções de apoio didático
            da FAUUSP: 1&#41; Canteiro Experimental “Antônio Domingos Battaglia”,
            desde 1997 e 2&#41; Atelier de Escultura “Caetano Fraccaroli”, desde
            1987.
          </span>
          <span>
            A título de prestação de serviços à comunidade, os docentes do AUT
            têm atuado como consultores ou assessores em projetos de arquitetura
            e urbanismo, e em design, nas áreas de tecnologia da construção,
            conforto ambiental e metodologias aplicadas à tecnologia da arquitetura
            e do urbanismo e do design, além de participarem da docência em cursos
            de especialização e de extensão, principalmente daqueles aprovados e
            reconhecidos pela Universidade de São Paulo.
          </span>
        </DivInfoText>
      </DivCard>
    </Conteudo>
  );
}

export default AUT;
