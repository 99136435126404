import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'rc-slider/assets/index.css';
import styled from 'styled-components';
import LineChart from '../charts/line';
import Loading from './loading';

function LineChartDocenteProdPremios(props) {
  const {
    dep, id, getDashboardCount, tipo, getCountDocente,
  } = props;
  const [lineData, setLineData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const data = {};
    getDashboardCount({
      ano_inicio: 1978, tipo, departamento: dep,
    }).then((responsePayload) => {
      data[dep] = responsePayload[dep];
      return getCountDocente({ id });
    }).then((resDoc) => {
      if (resDoc !== {}) {
        const aux = {};
        const keys = Object.keys(resDoc);
        keys.forEach((key) => {
          const docKeys = Object.keys(resDoc[key]);
          docKeys.forEach((year) => {
            if (!aux[year]) {
              aux[year] = resDoc[key][year];
            } else {
              aux[year] += resDoc[key][year];
            }
          });
        });
        data.DOCENTE = aux;
        setLineData(data);
        setIsLoading(false);
      }
    });
  }, []);

  return (
    <LineChartContainer>
      {
        isLoading
          ? <Loading />
          : <LineChart cachedData={lineData} changeLabels="docente" dep={dep} />
      }
    </LineChartContainer>
  );
}

LineChartDocenteProdPremios.propTypes = {
  dep: PropTypes.string.isRequired,
  getCountDocente: PropTypes.func.isRequired,
  getDashboardCount: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  tipo: PropTypes.string.isRequired,
};

const LineChartContainer = styled.div`
    padding: 20px;
    background-color: white;
`;

export default LineChartDocenteProdPremios;
