import axios from 'axios';
import NOPHOTO from './assets/no-photo.png';

const url = process.env.REACT_APP_BACKEND_URL;

const fetchApi = (base, params = {}) => {
  const request = url + base;

  return axios.get(request, { params }).then((res) => {
    if (res.status !== 200) {
      return null;
    }
    return res.data.result;
  });
};

const postApi = (base, params = {}) => {
  const request = url + base;

  return axios.post(request, { params }).then((res) => {
    if (res.status !== 200) {
      return null;
    }
    return res.data.result;
  });
};

const getDashboard = () => {
  return fetchApi('/dashboard/').then((data) => {
    return data;
  });
};

const getProdBib = (params) => {
  return fetchApi('/producao_bibliografica', params).then((data) => {
    return data;
  });
};

const getProdBibCount = (params) => {
  return fetchApi('/producao_bibliografica/count', params).then((data) => {
    return data;
  });
};

const getProdBibCountTipos = (params) => {
  return fetchApi('/producao_bibliografica/count_tipos', params).then((data) => {
    return data;
  });
};

const getProdBibKeywords = (params) => {
  return fetchApi('/producao_bibliografica/keywords', params).then((data) => {
    return data;
  });
};

const getProdBibMap = (params) => {
  return fetchApi('/producao_bibliografica/map', params).then((data) => {
    return data;
  });
};

const getProdBibMapNational = (params) => {
  return fetchApi('/producao_bibliografica/map-national', params).then((data) => {
    return data;
  });
};

const getProdBibRank = (params) => {
  return fetchApi('/producao_bibliografica/rank', params).then((data) => {
    return data;
  });
};

const getProdBibTipos = () => {
  return fetchApi('/producao_bibliografica/tipos').then((data) => {
    return data;
  });
};

const getProdArtMap = () => {
  return fetchApi('/producao_artistica/map').then((data) => {
    return data;
  });
};

const getProdArtMapNational = (params) => {
  return fetchApi('/producao_artistica/map-national', params).then((data) => {
    return data;
  });
};

const getProdTec = (params) => {
  return fetchApi('/producao_tecnica', params).then((data) => {
    return data;
  });
};

const getProdTecCount = (params) => {
  return fetchApi('/producao_tecnica/count', params).then((data) => {
    return data;
  });
};

const getProdTecCountTipos = (params) => {
  return fetchApi('/producao_tecnica/count_tipos', params).then((data) => {
    return data;
  });
};

const getProdTecKeywords = (params) => {
  return fetchApi('/producao_tecnica/keywords', params).then((data) => {
    return data;
  });
};

const getProdTecMap = (params) => {
  return fetchApi('/producao_tecnica/map', params).then((data) => {
    return data;
  });
};

const getProdTecMapNational = (params) => {
  return fetchApi('/producao_tecnica/map-national', params).then((data) => {
    return data;
  });
};

const getProdTecRank = (params) => {
  return fetchApi('/producao_tecnica/rank', params).then((data) => {
    return data;
  });
};

const getProdTecTipos = () => {
  return fetchApi('/producao_tecnica/tipos').then((data) => {
    return data;
  });
};

const getProdArt = (params) => {
  return fetchApi('/producao_artistica', params).then((data) => {
    return data;
  });
};

const getProdArtCount = (params) => {
  return fetchApi('/producao_artistica/count', params).then((data) => {
    return data;
  });
};

const getProdArtCountTipos = (params) => {
  return fetchApi('/producao_artistica/count_tipos', params).then((data) => {
    return data;
  });
};

const getProdArtKeywords = (params) => {
  return fetchApi('/producao_artistica/keywords', params).then((data) => {
    return data;
  });
};

const getProdArtRank = (params) => {
  return fetchApi('/producao_artistica/rank', params).then((data) => {
    return data;
  });
};

const getProdArtTipos = () => {
  return fetchApi('/producao_artistica/tipos').then((data) => {
    return data;
  });
};

const getOrientacao = () => {
  return fetchApi('/orientacao').then((data) => {
    return data;
  });
};

const getOrientacaoCount = (params) => {
  return fetchApi('/orientacao/count', params).then((data) => {
    return data;
  });
};

const getOrientacaoCountTipos = (params) => {
  return fetchApi('/orientacao/count_tipos', params).then((data) => {
    return data;
  });
};

const getOrientacaoKeywords = (params) => {
  return fetchApi('/orientacao/keywords', params).then((data) => {
    return data;
  });
};

const getOrientacaoMap = () => {
  return fetchApi('/orientacao/map').then((data) => {
    return data;
  });
};

const getOrientacaoMapNational = () => {
  return fetchApi('/orientacao/map-national').then((data) => {
    return data;
  });
};

const getOrientacaoRank = (params) => {
  return fetchApi('/orientacao/rank', params).then((data) => {
    return data;
  });
};

const getOrientacaoTipos = () => {
  return fetchApi('/orientacao/tipos').then((data) => {
    return data;
  });
};

const getBancas = () => {
  return fetchApi('/Bancas').then((data) => {
    return data;
  });
};

const getBancasCount = (params) => {
  return fetchApi('/bancas/count', params).then((data) => {
    return data;
  });
};

const getBancasCountTipos = (params) => {
  return fetchApi('/bancas/count_tipos', params).then((data) => {
    return data;
  });
};

const getBancasKeywords = (params) => {
  return fetchApi('/bancas/keywords', params).then((data) => {
    return data;
  });
};

const getBancasMap = () => {
  return fetchApi('/bancas/map').then((data) => {
    return data;
  });
};

const getBancasMapNational = () => {
  return fetchApi('/bancas/map-national').then((data) => {
    return data;
  });
};

const getBancasRank = (params) => {
  return fetchApi('/bancas/rank', params).then((data) => {
    return data;
  });
};

const getBancasTipos = () => {
  return fetchApi('/bancas/tipos').then((data) => {
    return data;
  });
};

const getPremiosRank = (params) => {
  return fetchApi('/premios/rank', params).then((data) => {
    return data;
  });
};

const getPremiosCount = (params) => {
  return fetchApi('/premios/count', params).then((data) => {
    return data;
  });
};

const getPessoa = (params) => {
  return fetchApi('/pessoa', params).then((data) => {
    return data;
  });
};

const getAllPessoa = () => {
  return fetchApi('/pessoa/all').then((data) => {
    return data;
  });
};

const getNomesPessoa = () => {
  return fetchApi('/pessoa/nomes').then((data) => {
    return data;
  });
};

const getCountPessoaPorDep = (params) => {
  return fetchApi('/pessoa/countPorDep', params).then((data) => {
    return data;
  });
};

const getKeywordsPessoa = (params) => {
  return fetchApi('/pessoa/keywords', params).then((data) => {
    return data;
  });
};

const getMapPessoa = (params) => {
  return fetchApi('/pessoa/map', params).then((data) => {
    return data;
  });
};

const getMapPessoaNational = (params) => {
  return fetchApi('/pessoa/map-national', params).then((data) => {
    return data;
  });
};

const getTiposProdArtPessoa = (params) => {
  return fetchApi('/pessoa/tiposProdArt', params).then((data) => {
    return data;
  });
};

const getCountProdArtPessoa = (params) => {
  return fetchApi('/pessoa/count/prodArt', params).then((data) => {
    return data;
  });
};

const getCountProdArtPessoaPorAno = (params) => {
  return fetchApi('/pessoa/countPorAno/prodArt', params).then((data) => {
    return data;
  });
};

const getTiposProdTecPessoa = (params) => {
  return fetchApi('/pessoa/tiposProdTec', params).then((data) => {
    return data;
  });
};

const getCountProdTecPessoa = (params) => {
  return fetchApi('/pessoa/count/prodTec', params).then((data) => {
    return data;
  });
};

const getCountProdTecPessoaPorAno = (params) => {
  return fetchApi('/pessoa/countPorAno/prodTec', params).then((data) => {
    return data;
  });
};

const getTiposProdBibPessoa = (params) => {
  return fetchApi('/pessoa/tiposProdBib', params).then((data) => {
    return data;
  });
};

const getCountProdBibPessoa = (params) => {
  return fetchApi('/pessoa/count/prodBib', params).then((data) => {
    return data;
  });
};

const getCountProdBibPessoaPorAno = (params) => {
  return fetchApi('/pessoa/countPorAno/prodBib', params).then((data) => {
    return data;
  });
};

const getTiposPartPessoa = (params) => {
  return fetchApi('/pessoa/tiposPart', params).then((data) => {
    return data;
  });
};

const getCountPartPessoa = (params) => {
  return fetchApi('/pessoa/count/part', params).then((data) => {
    return data;
  });
};

const getCountPartPessoaPorAno = (params) => {
  return fetchApi('/pessoa/countPorAno/part', params).then((data) => {
    return data;
  });
};

const getTiposBancaPessoa = (params) => {
  return fetchApi('/pessoa/tiposBanca', params).then((data) => {
    return data;
  });
};

const getCountBancaPessoa = (params) => {
  return fetchApi('/pessoa/count/banca', params).then((data) => {
    return data;
  });
};

const getCountBancaPessoaPorAno = (params) => {
  return fetchApi('/pessoa/countPorAno/banca', params).then((data) => {
    return data;
  });
};

const getTiposOriPessoa = (params) => {
  return fetchApi('/pessoa/tiposOri', params).then((data) => {
    return data;
  });
};

const getCountOriPessoa = (params) => {
  return fetchApi('/pessoa/count/ori', params).then((data) => {
    return data;
  });
};

const getCountOriPessoaPorAno = (params) => {
  return fetchApi('/pessoa/countPorAno/ori', params).then((data) => {
    return data;
  });
};

const getCountPremioPessoaPorAno = (params) => {
  return fetchApi('/pessoa/countPorAno/premio', params).then((data) => {
    return data;
  });
};

const getImg = async (id) => {
  try {
    return fetchApi('/pessoa/picture', { id }).then((data) => {
      return `http://servicosweb.cnpq.br/wspessoa/servletrecuperafoto?tipo=1&id=${data}`;
    });
  } catch (error) {
    return NOPHOTO;
  }
};

const getSearchResult = (params) => {
  return fetchApi('/dashboard/search', params).then((data) => {
    return data;
  });
};

const getDashboardCount = (params) => {
  return fetchApi('/dashboard/count', params).then((data) => {
    return data;
  });
};

const getDashboardCountByDep = (params) => {
  return fetchApi('/dashboard/countByDep', params).then((data) => {
    return data;
  });
};

const getDashboardByDep = (params) => {
  return fetchApi('/dashboard/indexByDep', params).then((data) => {
    return data;
  });
};

const getDashboardKeywords = (params) => {
  return fetchApi('/dashboard/keywords', params).then((data) => {
    return data;
  });
};

const getDashboardMap = (params) => {
  return fetchApi('/dashboard/map', params).then((data) => {
    return data;
  });
};

const getDashboardMapNational = (params) => {
  return fetchApi('/dashboard/map-national', params).then((data) => {
    return data;
  });
};

const getDashboardCountByDepTipo = (params) => {
  return fetchApi('/dashboard/countByDepTipo', params).then((data) => {
    return data;
  });
};

const getLastUpdateDate = (params) => {
  return fetchApi('/dashboard/last-updated', params).then((data) => {
    return data;
  });
};

const getCountAllPessoa = (params) => {
  return fetchApi('/pessoa/countAll', params).then((data) => {
    return data;
  });
};

const postQuestion = (params) => {
  return postApi('/contato/faq', params).then((data) => {
    return data;
  });
};

export {
  getDashboard,
  getProdBib,
  getProdBibCount,
  getProdBibCountTipos,
  getProdBibKeywords,
  getProdBibMap,
  getProdBibMapNational,
  getProdBibRank,
  getProdBibTipos,
  getProdTec,
  getProdTecCount,
  getProdTecCountTipos,
  getProdTecKeywords,
  getProdTecMap,
  getProdTecMapNational,
  getProdTecRank,
  getProdTecTipos,
  getProdArt,
  getProdArtCount,
  getProdArtCountTipos,
  getProdArtKeywords,
  getProdArtMap,
  getProdArtMapNational,
  getProdArtRank,
  getProdArtTipos,
  getOrientacao,
  getOrientacaoCount,
  getOrientacaoCountTipos,
  getOrientacaoKeywords,
  getOrientacaoMap,
  getOrientacaoMapNational,
  getOrientacaoRank,
  getOrientacaoTipos,
  getBancas,
  getBancasCount,
  getBancasCountTipos,
  getBancasKeywords,
  getBancasMap,
  getBancasMapNational,
  getBancasRank,
  getBancasTipos,
  getPremiosRank,
  getPremiosCount,
  getPessoa,
  getAllPessoa,
  getNomesPessoa,
  getCountPessoaPorDep,
  getKeywordsPessoa,
  getMapPessoa,
  getMapPessoaNational,
  getTiposProdArtPessoa,
  getCountProdArtPessoa,
  getCountProdArtPessoaPorAno,
  getTiposProdTecPessoa,
  getCountProdTecPessoa,
  getCountProdTecPessoaPorAno,
  getTiposProdBibPessoa,
  getCountProdBibPessoa,
  getCountProdBibPessoaPorAno,
  getTiposPartPessoa,
  getCountPartPessoa,
  getCountPartPessoaPorAno,
  getTiposBancaPessoa,
  getCountBancaPessoa,
  getCountBancaPessoaPorAno,
  getTiposOriPessoa,
  getCountOriPessoa,
  getCountOriPessoaPorAno,
  getCountPremioPessoaPorAno,
  getImg,
  getSearchResult,
  getDashboardCount,
  getDashboardCountByDep,
  getDashboardByDep,
  getDashboardKeywords,
  getDashboardMap,
  getDashboardMapNational,
  getDashboardCountByDepTipo,
  getLastUpdateDate,
  getCountAllPessoa,
  postQuestion,
};
