import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';
import { setColorMenu } from '../components/btnFunctions';
import {
  getDashboardCount, getDashboard, getDashboardMap, getDashboardMapNational,
} from '../api';
import {
  Conteudo, DivCard, DivGraph, DivInfoText, DivTitle, DivInfoProducao, DivTotal,
} from '../styles';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import LineChartFau from '../components/chart-line-fau';
import BarChartFau from '../components/chart-bar-fau';
import MapProducao from '../components/map-producao';
import MapProducaoNacional from '../components/map-producao-nacional';
import Loading from '../components/loading';

function Home() {
  const [producoesCount, setProducoesCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getDashboard().then((responsePayload) => {
      const total = Object.values(responsePayload).reduce((accumulator, value) => {
        return accumulator + value;
      }, 0);
      setIsLoading(false);
      setProducoesCount(total);
    });
  }, []);

  if (isLoading) {
    return (
      <Conteudo
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Loading />
      </Conteudo>
    );
  }

  return <HomeData producoesCount={producoesCount} />;
}

function HomeData(props) {
  const { producoesCount } = props;
  useEffect(() => {
    setColorMenu();
  }, []);

  return (
    <Conteudo style={{ justifyContent: 'start' }}>
      <DivCard style={{ border: 'none' }}>
        <DivInfoProducao>
          <DivTitle style={{ padding: 0 }}>
            <span style={{ fontSize: 32 }}>Total FAUUSP</span>
          </DivTitle>
          <DivTotal style={{ padding: 0 }}>
            <span style={{ fontSize: 100 }}>
              {Intl.NumberFormat().format(producoesCount)}
            </span>
            <span>resultados</span>
          </DivTotal>
        </DivInfoProducao>
        <DivGraph>
          <Carousel
            showArrows
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
            autoPlay={false}
            infiniteLoop
            interval={5000}
          >
            <div>
              <LineChartFau
                getDashboardCount={getDashboardCount}
              />
            </div>
            <div>
              <BarChartFau
                getCountDashboard={getDashboard}
              />
            </div>
            <div>
              <MapProducao
                getMap={getDashboardMap}
              />
            </div>
            <div>
              <MapProducaoNacional
                getMap={getDashboardMapNational}
              />
            </div>
          </Carousel>
        </DivGraph>
        <DivInfoText style={{ padding: '0px 0px 0px 5px' }}>
          <span style={{ fontSize: 13.7 }}>
            A
            {' '}
            <b>FAU Aberta</b>
            {' '}
            é uma iniciativa da
            {' '}
            <b>
              Comissão de Pesquisa da FAUUSP (CPq-FAUUSP)
            </b>
            {' '}
            com o intuito de dar visibilidade à produção intelectual
            da faculdade, difundindo sua especificidade e diversidade através de
            dados extraídos do currículo Lattes de docentes. Destinado à
            comunidade acadêmica em geral, a FAU Aberta resulta da cooperação
            entre a
            {' '}
            <b>FAU</b>
            {' '}
            e o
            {' '}
            <b>Instituto de Matemática e Estatística (IME-USP)</b>
            ,
            representado por um grupo de alunos da disciplina Laboratório de
            Programação Extrema (MAC0342), coordenada
            pelo Prof. Dr. Alfredo Goldman Vel Lejbman, para desenvolver uma base
            de dados automatizada que alimentará a plataforma web de acesso
            aberto. O projeto envolveu a participação de docentes de ambas às
            unidades, discentes de graduação, bibliotecários e a Superintendência
            da Tecnologia e Informação da USP (STI-USP).
            É compromisso das universidades públicas ampliar o acesso às suas
            pesquisas acadêmicas e aos seus resultados (produções bibliográficas,
            técnicas e artísticas), permitindo quantificá-los e qualificá-los em
            termos de impacto social, impacto econômico, inovação tecnológica e
            desdobramentos em políticas públicas e de sustentabilidade. Esta segunda
            etapa do projeto, desenvolvida em 2020 e implementada em 2021, aprimora
            a primeira versão piloto desenvolvida em 2019. Atualmente, esse projeto
            em andamento, disponibiliza apenas a produção intelectual dos docentes
            da FAUUSP cadastrada até novembro de 2020, quando os dados foram coletados.
            Para a próxima etapa de desenvolvimento e automatização do sistema,
            seguiremos contando com o apoio do IME-USP, do STI-USP, STI-FAU e da
            Diretoria da FAUUSP.
          </span>
          <span style={{ marginBottom: 5, marginTop: 0 }}>
            <b>coordenação:</b>
            {' '}
            Beatriz Bueno, Artur Rozestraten, Leandro Velloso, Amarílis  Corrêa,
            Harley Macedo e Deidson Rafael Trindade
          </span>
          <span style={{ marginBottom: 0 }}>
            <b>etapa I, 2019</b>
            {' '}
            |
            {' '}
            <b>desenvolvimento:</b>
            {' '}
            César Fernandes, Leonardo Aguilar, Larissa Sala, Mateus dos Anjos,
            Matheus Cunha, Nathalia Borin, Pedro Santos, Victor Batistella
            {' '}
            <br />
            <b>etapa II, 2020</b>
            {' '}
            |
            {' '}
            <b>desenvolvimento:</b>
            {' '}
            Kaique Komata, Jean Pereira, Luciana Marques, Priscila Lima |
            {' '}
            <b>design:</b>
            {' '}
            Luís Felipe Abbud
            <br />
            <b>etapa III, 2021</b>
            {' '}
            |
            {' '}
            <b>desenvolvimento:</b>
            {' '}
            João Daniel, João Gabriel Lembo, Leonardo Pereira, Victor Lima |
            {' '}
            <b>design:</b>
            {' '}
            Gustavo Machado. coordenação: Luís Felipe Abbud
          </span>
        </DivInfoText>
      </DivCard>
    </Conteudo>
  );
}

HomeData.propTypes = {
  producoesCount: PropTypes.number.isRequired,
};

export default Home;
