import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'rc-slider/assets/index.css';
import styled from 'styled-components';
import LineChart from '../charts/line';
import Loading from './loading';

function LineChartDepartamento(props) {
  const { dep, getDashboardCount, getDepCount } = props;
  const [lineData, setLineData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const data = {};
    getDashboardCount({ ano_inicio: 1978 }).then((responsePayload) => {
      data.FAU = responsePayload.FAU;
    }).then(() => {
      getDepCount({ ano_inicio: 1978, departamento: dep }).then((responsePayload) => {
        data[dep] = responsePayload[dep];
        setLineData(data);
        setIsLoading(false);
      });
    });
  }, []);

  return (
    <LineChartContainer>
      {
        isLoading
          ? <Loading />
          : <LineChart cachedData={lineData} changeLabels="deps" dep={dep} />
      }
    </LineChartContainer>
  );
}

LineChartDepartamento.propTypes = {
  dep: PropTypes.string.isRequired,
  getDashboardCount: PropTypes.func.isRequired,
  getDepCount: PropTypes.func.isRequired,
};

const LineChartContainer = styled.div`
    padding: 20px;
    background-color: white;
`;

export default LineChartDepartamento;
