const ProdBibDescription = 'Apresenta aspectos da produção bibliográfica de cada departamento da FAU, relativos às publicações em periódicos credenciados, livros, capítulos de livros, anais de eventos, resumos em anais de eventos, prefácios/ posfácios, traduções, jornais (matérias na imprensa) e outros.';
const ProdTecDescription = 'Apresenta aspectos da produção técnica de cada departamento da FAU, relativos à organização de evento, assessorias e consultorias, apresentação de trabalhos, participação em programa de rádio ou TV, projetos e produtos de desenho industrial, relatórios de pesquisa, desenvolvimento de material didático ou institucional, produtos tecnológicos, trabalhos técnicos, mídias sociais/ websites/ blogs, maquetes, editoração, cursos de curta duração ministrados, patentes, processos ou técnicas, softwares, cartas ou mapas similares, manutenção de obras artísticas e outros.';
const ProdArtDescription = 'Apresenta aspectos da produção artística de cada departamento da FAU, relativos às obras de artes visuais, cursos de curta duração e demais atividades em música, artes cênicas e artes visuais.';
const OrientacaoDescription = 'Apresenta orientações de graduandos e pós-graduandos.';
const BancasDescription = 'Apresenta a participação em bancas de trabalhos de conclusão, na graduação e na pós-graduação, e em bancas de comissões julgadoras.';
const PremiosDescription = 'Apresenta prêmios e títulos recebidos.';

export {
  ProdBibDescription,
  ProdTecDescription,
  ProdArtDescription,
  OrientacaoDescription,
  BancasDescription,
  PremiosDescription,
};
