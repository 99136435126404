import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'rc-slider/assets/index.css';
import styled from 'styled-components';
import LineChart from '../charts/line';
import Loading from './loading';

function LineChartFau(props) {
  const { getDashboardCount } = props;
  const [lineData, setLineData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const data = {};
    getDashboardCount({ ano_inicio: 1978 }).then((responsePayload) => {
      data.FAU = responsePayload.FAU;
      setLineData(data);
      setIsLoading(false);
    });
  }, []);

  return (
    <LineChartContainer>
      {
        isLoading
          ? <Loading />
          : <LineChart cachedData={lineData} changeLabels="fau" />
      }
    </LineChartContainer>
  );
}

LineChartFau.propTypes = {
  getDashboardCount: PropTypes.func.isRequired,
};

const LineChartContainer = styled.div`
    padding: 20px;
    background-color: white;
`;

export default LineChartFau;
