import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {
  Conteudo, DivCard, DivGraph, DivInfoProducao, DivInfoText, DivTitle, DivTotal,
} from '../styles';
import BarChartProducao from './chart-bar-producao';
import KeywordsProducao from './keywords-producao';
import MapProducao from './map-producao';
import MapProducaoNacional from './map-producao-nacional';
import LineChartProducao from './chart-line-producao';
import LineChartProducaoFau from './chart-line-producao-fau';
import Loading from './loading';
import { getDashboard } from '../api';

function ProducaoFau(props) {
  const {
    getCount,
    getKeywords,
    getMap,
    getNationalMap,
    getTypes,
    infoText,
    isBancasPage,
    isOrientacaoPage,
    isPremiosPage,
    productionType,
    setMenuItemColor,
  } = props;
  const [dashboardData, setDashboardData] = useState(null);
  const [count, setCount] = useState(null);

  useEffect(() => {
    setMenuItemColor();
  }, []);

  useEffect(() => {
    getDashboard().then((responsePayload) => {
      setDashboardData(responsePayload);
    });
    getCount({ ano_inicio: 1978 }).then((responsePayload) => {
      setCount(responsePayload);
    });
  }, []);

  if (!dashboardData || !count) {
    return (
      <Conteudo
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Loading />
      </Conteudo>
    );
  }

  let carouselItems = [
    <div>
      <KeywordsProducao
        getKeywords={getKeywords}
      />
    </div>,
    <div>
      <MapProducao
        getMap={getMap}
      />
    </div>,
    <div>
      <MapProducaoNacional
        getMap={getNationalMap}
      />
    </div>,
    <div>
      <BarChartProducao
        getCount={getCount}
        getTipos={getTypes}
      />
    </div>,
    <div>
      <LineChartProducaoFau
        count={count}
      />
    </div>,
    <div>
      <LineChartProducao
        count={count}
      />
    </div>,
  ];

  // Remove national map chart
  if (isOrientacaoPage || isBancasPage) {
    carouselItems.splice(2, 1);
  }

  // Keep only line charts
  if (isPremiosPage) {
    carouselItems = carouselItems.slice(-2);
  }

  return (
    <Conteudo style={{ justifyContent: 'start' }}>
      <DivCard style={{ border: 'none' }}>
        <DivInfoProducao>
          <DivTitle>
            <span>{productionType}</span>
          </DivTitle>
          <DivTotal>
            <span style={{ fontSize: 100 }}>
              {Intl.NumberFormat().format(dashboardData[productionType])}
            </span>
            <span>resultados</span>
          </DivTotal>
        </DivInfoProducao>
        <DivGraph>
          <Carousel
            height="100%"
            showArrows
            showThumbs={false}
            showIndicators={false}
            autoPlay
            showStatus={false}
            infiniteLoop
            interval={5000}
          >
            { carouselItems }
          </Carousel>
        </DivGraph>
        <DivInfoText>
          <span
            style={{
              fontSize: 25,
              fontWeight: 'bold',
              marginTop: -3.8,
            }}
          >
            Apresentação
          </span>
          <span>{infoText}</span>
        </DivInfoText>
      </DivCard>
    </Conteudo>
  );
}

ProducaoFau.propTypes = {
  getCount: PropTypes.func.isRequired,
  getKeywords: PropTypes.func.isRequired,
  getMap: PropTypes.func.isRequired,
  getNationalMap: PropTypes.func.isRequired,
  getTypes: PropTypes.func.isRequired,
  infoText: PropTypes.string.isRequired,
  isBancasPage: PropTypes.bool,
  isOrientacaoPage: PropTypes.bool,
  isPremiosPage: PropTypes.bool,
  productionType: PropTypes.string.isRequired,
  setMenuItemColor: PropTypes.func.isRequired,
};

ProducaoFau.defaultProps = {
  isBancasPage: false,
  isOrientacaoPage: false,
  isPremiosPage: false,
};

export default ProducaoFau;
