import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'rc-slider/assets/index.css';
import styled from 'styled-components';
import LineChart from '../charts/line';
import Loading from './loading';

function LineChartProducaoFau(props) {
  const { count } = props;
  const [lineData, setLineData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const data = { FAU: {} };
    const aux = {};
    const departments = ['AUH', 'AUT', 'AUP'];
    departments.forEach((dep) => {
      const productionsCountByYear = {};
      Object.keys(count[dep]).forEach((productionType) => {
        Object.keys(count[dep][productionType]).forEach((year) => {
          const yearCount = count[dep][productionType][year];
          productionsCountByYear[year] = (productionsCountByYear[year] || 0) + yearCount;
        });
      });

      aux[dep] = productionsCountByYear;
    });

    departments.forEach((dep) => {
      Object.keys(aux[dep]).forEach((year) => {
        if (!data.FAU[year]) {
          data.FAU[year] = aux[dep][year];
        } else {
          data.FAU[year] += aux[dep][year];
        }
      });
    });

    setLineData(data);
    setIsLoading(false);
  }, []);

  return (
    <LineChartContainer>
      {
        isLoading
          ? <Loading />
          : <LineChart cachedData={lineData} changeLabels="fau" />
      }
    </LineChartContainer>
  );
}

LineChartProducaoFau.propTypes = {
  count: PropTypes.object.isRequired,
};

const LineChartContainer = styled.div`
    padding: 20px;
    background-color: white;
`;

export default LineChartProducaoFau;
