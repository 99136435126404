import React from 'react';
import ProducaoFau from '../components/producao-fau';
import {
  getBancasKeywords,
  getBancasMap,
  getBancasMapNational,
  getBancasCount,
  getBancasTipos,
} from '../api';
import { setColorBanca } from '../components/btnFunctions';
import { BancasDescription } from '../staticTexts';

function Bancas() {
  return (
    <ProducaoFau
      getCount={getBancasCount}
      getKeywords={getBancasKeywords}
      getMap={getBancasMap}
      getNationalMap={getBancasMapNational}
      getTypes={getBancasTipos}
      infoText={BancasDescription}
      isBancasPage
      productionType="Bancas"
      setMenuItemColor={setColorBanca}
    />
  );
}

export default Bancas;
