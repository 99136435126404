import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as S from '../styles';
import StackedBar from '../charts/bar';
import 'rc-slider/assets/index.css';
import { dict } from '../dict';
import Loading from './loading';

function BarChartProducao(props) {
  const { getCount, getTipos } = props;
  const [allCategories, setAllCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [barData, setBarData] = useState(null);
  const [allCount, setAllCount] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setCategories(allCategories);
  }, [allCategories]);

  useEffect(() => {
    const data = {
      labels: ['AUH', 'AUT', 'AUP'],
      datasets: categories.map((category) => {
        return {
          label: dict(category),
          data: [],
          category,
        };
      }),
    };

    data.datasets.forEach((dataset, index) => {
      data.labels.forEach((dep) => {
        dataset.backgroundColor = S.barColors.FAU[index % 6];
        dataset.data.push(Object.values(allCount[dep][dataset.category])
          .reduce((accumulator, current) => {
            return accumulator + current;
          }));
      });
    });

    setBarData(data);
  }, [categories]);

  useEffect(() => {
    getTipos().then((res) => {
      getCount({ tipos: res }).then((count) => {
        setAllCount(count);
        setAllCategories(res);
        setIsLoading(false);
      });
    });
  }, []);

  return (
    <BarChartContainer>
      {
        isLoading
          ? <Loading />
          : (
            <>
              <StackedBar data={barData} />
            </>
          )
      }
    </BarChartContainer>
  );
}

BarChartProducao.propTypes = {
  getCount: PropTypes.func.isRequired,
  getTipos: PropTypes.func.isRequired,
};

const BarChartContainer = styled.div`
    background-color: white;
    padding: 25px;
`;

export default BarChartProducao;
