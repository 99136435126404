import React from 'react';
import ProducaoFau from '../components/producao-fau';
import {
  getOrientacaoCount,
  getOrientacaoKeywords,
  getOrientacaoMap,
  getOrientacaoTipos,
} from '../api';
import { setColorOrientacao } from '../components/btnFunctions';
import { OrientacaoDescription } from '../staticTexts';

function Orientacoes() {
  return (
    <ProducaoFau
      getCount={getOrientacaoCount}
      getKeywords={getOrientacaoKeywords}
      getMap={getOrientacaoMap}
      getTypes={getOrientacaoTipos}
      infoText={OrientacaoDescription}
      isOrientacaoPage
      productionType="Orientação"
      setMenuItemColor={setColorOrientacao}
    />
  );
}

export default Orientacoes;
