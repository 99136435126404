import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Container } from '../styles';

function Menu(props) {
  const { setIsShowingFAQ } = props;
  let path = '/';
  const history = useHistory();
  const changeRoute = () => {
    history.push(path);
  };

  function setColor(e, event) {
    setTimeout(() => {
      const colorBtn = window.getComputedStyle(e).backgroundColor;
      const colorText = window.getComputedStyle(e).color;

      if (e.id === 'FAU') {
        path = '/';
        changeRoute();

        e.parentElement.querySelectorAll('a').forEach((a) => {
          a.style.backgroundColor = '';
          a.style.color = '';
          a.style.border = '1px solid';
        });
      }

      if (e.id === 'DOCENTES') {
        path = '/pessoa/nomes/';
        changeRoute();
        const btnHome = document.querySelector('#FAU');
        btnHome.style.backgroundColor = '';
        btnHome.style.color = '';
        btnHome.style.border = '1px solid';
        document.querySelectorAll('a[id^=AU]').forEach((a) => {
          a.style.border = '2.5px solid';
          a.style.backgroundColor = '';
          a.style.color = '';
          document.querySelectorAll('#PUBLICACAO').forEach((a2) => {
            a2.style.backgroundColor = '';
            a2.style.color = '';
            a2.style.border = '1px solid';
          });
        });
      }

      if (e.id === 'AUH' || e.id === 'AUP' || e.id === 'AUT') {
        document.querySelectorAll('a[id^=AU]').forEach((a) => {
          a.style.border = '1px solid';
          a.style.backgroundColor = '';
          a.style.color = '#000';
        });

        document.querySelectorAll('#PUBLICACAO').forEach((a) => {
          a.style.backgroundColor = '';
          a.style.color = '';
          a.style.border = '1px solid';
        });

        if (document.querySelector('#DOCENTES').style.backgroundColor === 'rgb(0, 0, 0)') {
          document.querySelectorAll('a[id^=AU]').forEach((a) => {
            a.style.border = '2.5px solid';
            a.style.backgroundColor = '';
            a.style.color = '#000';
          });

          path = `/pessoa/nome/${e.id}`;
          changeRoute();
        } else {
          document.querySelectorAll('#PUBLICACAO').forEach((a) => {
            a.style.backgroundColor = '';
            a.style.color = '';
            a.style.border = '1px solid';
          });
          path = `/${e.id}`;
          changeRoute();
        }
      }

      if (e.id === 'PUBLICACAO') {
        const filtroDep = [...document.querySelectorAll('a[id^=AU]')].reduce((valid, a) => {
          if (a.style.border === 'none') {
            valid = true;
          } else if (a.style.border === '2.5px solid') {
            valid = true;
          }

          return valid;
        }, false);

        const filtroPub = [...document.querySelectorAll('#PUBLICACAO')].reduce((valid, a) => {
          if (a.style.border === '2.5px solid') {
            valid = true;
          }

          return valid;
        }, false);

        if (filtroDep) {
          e.parentElement.querySelectorAll('a').forEach((a) => {
            a.style.backgroundColor = '';
            a.style.color = '';
            a.style.border = '1px solid';
          });
        }

        if (!filtroDep && filtroPub) {
          const id = event.split('/')[5];
          path = `/pessoa/${e.innerText.replace(/\s+/g, '')}/${id}`;
          changeRoute();
        } else {
          document.querySelectorAll('#PUBLICACAO').forEach((a) => {
            a.style.backgroundColor = '';
            a.style.color = '';
            a.style.border = '1px solid';
          });
          path = `/${e.name}`;
          changeRoute();
        }
      }

      if (e.id === 'FAQ') {
        setIsShowingFAQ(true);
      } else {
        setIsShowingFAQ(false);
        e.style.backgroundColor = colorBtn;
        e.style.border = 'none';
        e.style.color = colorText;
      }
    });
  }

  return (
    <Container id="links">
      <a
        to="/"
        id="FAU"
        onClick={(event) => {
          return setColor(event.currentTarget);
        }}
      >
        FAU Aberta
      </a>
      <a
        to="/pessoa/nomes"
        id="DOCENTES"
        onClick={(event) => {
          return setColor(event.currentTarget);
        }}
      >
        Docentes
      </a>
      <a
        to="#"
        id="AUH"
        onClick={(event) => {
          return setColor(event.currentTarget);
        }}
      >
        AUH
      </a>
      <a
        to="#"
        id="AUT"
        onClick={(event) => {
          return setColor(event.currentTarget);
        }}
      >
        AUT
      </a>
      <a
        to="#"
        id="AUP"
        onClick={(event) => {
          return setColor(event.currentTarget);
        }}
      >
        AUP
      </a>
      <a
        name="producao-bibliografica"
        id="PUBLICACAO"
        onClick={(event) => {
          return setColor(event.currentTarget, window.location.href);
        }}
      >
        Produção Bibliográfica
      </a>
      <a
        name="producao-artistica"
        id="PUBLICACAO"
        onClick={(event) => {
          return setColor(event.currentTarget, window.location.href);
        }}
      >
        Produção Artística
      </a>
      <a
        name="producao-tecnica"
        id="PUBLICACAO"
        onClick={(event) => {
          return setColor(event.currentTarget, window.location.href);
        }}
      >
        Produção Técnica
      </a>
      <a
        name="orientacoes"
        id="PUBLICACAO"
        onClick={(event) => {
          return setColor(event.currentTarget, window.location.href);
        }}
      >
        Orientações
      </a>
      <a
        name="bancas"
        id="PUBLICACAO"
        onClick={(event) => {
          return setColor(event.currentTarget, window.location.href);
        }}
      >
        Bancas
      </a>
      <a
        name="premios"
        id="PUBLICACAO"
        onClick={(event) => {
          return setColor(event.currentTarget, window.location.href);
        }}
      >
        Prêmios e Títulos
      </a>
      <a
        name="faq"
        id="FAQ"
        onClick={(event) => {
          return setColor(event.currentTarget, window.location.href);
        }}
      >
        FAQ
      </a>
    </Container>
  );
}

Menu.propTypes = {
  setIsShowingFAQ: PropTypes.func.isRequired,
};

export default Menu;
