import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CenteredColumn, DivTitle } from '../styles';
import { postQuestion } from '../api';

function FAQ(props) {
  const { setIsShowingFAQ } = props;
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(-1);
  const [isShowingEmailSentMessage, setIsShowingEmailSentMessage] = useState(false);
  const [isShowingErrorMessage, setIsShowingErrorMessage] = useState(false);
  const [hasEmailError, setHasEmailError] = useState(false);
  const [hasSubjectError, setHasSubjectError] = useState(false);
  const [hasMessageError, setHasMessageError] = useState(false);

  const handleQuestionItemClick = (event) => {
    const index = Number(event.target.getAttribute('data-index'));
    if (index === selectedQuestionIndex) {
      setSelectedQuestionIndex(-1);
      return;
    }

    setSelectedQuestionIndex(index);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const form = event.target;
    const params = {};
    for (let i = 0; i < form.elements.length; i++) {
      const element = form.elements[i];
      if (element.name) {
        params[element.name] = element.value;
      }
    }

    let hasError = false;
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(form.email.value)) {
      hasError = true;
      setHasEmailError(true);
      setTimeout(() => {
        setHasEmailError(false);
      }, 3000);
    }

    if (!form.subject.value) {
      hasError = true;
      setHasSubjectError(true);
      setTimeout(() => {
        setHasSubjectError(false);
      }, 3000);
    }

    if (!form.message.value) {
      hasError = true;
      setHasMessageError(true);
      setTimeout(() => {
        setHasMessageError(false);
      }, 3000);
    }

    if (hasError) {
      return;
    }

    postQuestion(params).then(() => {
      setIsShowingEmailSentMessage(true);
      setTimeout(() => {
        setIsShowingEmailSentMessage(false);
      }, 3000);
    }).catch(() => {
      setIsShowingErrorMessage(true);
      setTimeout(() => {
        setIsShowingErrorMessage(false);
      }, 3000);
    });
  };

  let hasError = false;
  if (hasEmailError || hasSubjectError || hasMessageError) {
    hasError = true;
  }

  const questions = [
    {
      question: 'O que é o projeto CPQs abertas?',
      answer: (
        <>
          O intuito principal deste projeto é dar visibilidade
          quantitativa e qualitativamente à produção intelectual da
          faculdade, difundindo sua especificidade e diversidade através
          de dados extraídos do currículo Lattes de seus docentes, tanto
          para a comunidade interna, FAU e USP, quanto para a comunidade
          externa, contribuindo assim para uma melhor compreensão da
          sociedade em geral quanto ao trabalho e às contribuições
          advindas das atividades desenvolvidas na Universidade.
        </>
      ),
    },
    {
      question: 'Como são realizadas as coletas de informações do site?',
      answer: (
        <>
          Os dados exibidos são provenientes do Lattes. nos casos
          de mapas, usamos como referência a capital dos estados
          (ou países, se for o caso).
        </>
      ),
    },
    {
      question: 'O quão precisos são os dados utilizados?',
      answer: (
        <>
          Capturamos os dados públicos dos CV Lattes
          periodicamente, sendo que a data da coleta está indicada na
          página de abertura.
        </>
      ),
    },
    {
      question: 'A quais institutos o projeto se vincula hoje?',
      answer: (
        <>
          O projeto começou pela FAU e a segunda unidade a ser
          atendida será a FEA-RP.
        </>
      ),
    },
    {
      question: 'Percebi incongruências nos meus dados apresentados na '
                + 'plataforma FAU Aberta, o que devo fazer?',
      answer: (
        <>
          Reveja o preenchimento de seu CV Lattes. A completude
          e a precisão neste preenchimento são fundamentais para a
          congruência dos dados apresentados. A próxima atualização do
          sistema deve apresentar as devidas correções.
        </>
      ),
    },
    {
      question: 'Minha dúvida não está aqui. O que fazer?',
      answer: (
        <>
          Caso você tenha alguma dúvida que não tenha sido
          respondida, não hesite em nos contatar! Basta preencher as
          informações abaixo que te mandaremos um e-mail esclarecendo
          quaisquer perguntas
          <form
            style={{ marginTop: '2rem' }}
            onSubmit={handleFormSubmit}
          >
            <div style={{ marginBottom: '0.25rem' }}>
              <input
                style={{
                  border: '1px solid #000000',
                  borderRadius: '0',
                  paddingLeft: '0.25rem',
                  width: '100%',
                }}
                name="name"
                id="name"
                placeholder="Nome completo"
              />
            </div>
            <div style={{ marginBottom: '0.25rem' }}>
              <input
                style={{
                  border: hasEmailError ? '1px solid #FF0000' : '1px solid #000000',
                  borderRadius: '0',
                  paddingLeft: '0.25rem',
                  width: '100%',
                }}
                name="email"
                id="email"
                placeholder="E-mail"
              />
            </div>
            <div style={{ marginBottom: '0.25rem' }}>
              <input
                style={{
                  border: hasSubjectError ? '1px solid #FF0000' : '1px solid #000000',
                  borderRadius: '0',
                  paddingLeft: '0.25rem',
                  width: '100%',
                }}
                name="subject"
                id="subject"
                placeholder="Assunto"
              />
            </div>
            <textarea
              style={{
                border: hasMessageError ? '1px solid #FF0000' : '1px solid #000000',
                borderRadius: '0',
                fontFamily: 'Arial',
                paddingLeft: '0.25rem',
                resize: 'none',
                width: '100%',
              }}
              name="message"
              id="message"
              placeholder="Descrição da dúvida"
              rows="7"
            />

            <div style={{ textAlign: 'center' }}>
              <button
                type="submit"
                style={{
                  background: '#000000',
                  border: '0',
                  color: '#FFFFFF',
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  padding: '0.25rem 1rem',
                }}
              >
                Enviar
              </button>
            </div>

            {isShowingEmailSentMessage && (
              <div
                style={{
                  marginTop: '1rem',
                  textAlign: 'center',
                }}
              >
                E-mail enviado com sucesso!
              </div>
            )}

            {isShowingErrorMessage && (
              <div
                style={{
                  color: '#FF0000',
                  marginTop: '1rem',
                  textAlign: 'center',
                }}
              >
                Erro no envio, tente novamente mais tarde!
              </div>
            )}

            {hasError && (
              <div
                style={{
                  color: '#FF0000',
                  fontWeight: 'bold',
                  marginTop: '1rem',
                  textAlign: 'center',
                }}
              >
                Preencha todos os campos e/ou verifique a formatação!
              </div>
            )}
          </form>
        </>
      ),
    },
  ];

  const questionsCount = questions.length;
  const questionItems = questions.map((question, index) => {
    return (
      <li
        key={question.question}
        style={{
          borderBottom: (index < questionsCount - 1 ? '1px solid black' : ''),
          padding: '0.4rem 0',
        }}
      >
        <button
          style={{
            background: 'transparent',
            border: '0',
            cursor: 'pointer',
            fontSize: '15px',
            fontWeight: 'bold',
            padding: '0',
            textAlign: 'left',
          }}
          data-index={index}
          type="button"
          onClick={handleQuestionItemClick}
        >
          {question.question}
          {selectedQuestionIndex === index && <>&nbsp;&#9660;</>}
          {selectedQuestionIndex !== index && <>&nbsp;&#9658;</>}
        </button>
        <div
          style={{
            display: (selectedQuestionIndex === index ? 'block' : 'none'),
            marginTop: '0.25rem',
          }}
        >
          {question.answer}
        </div>
      </li>
    );
  });

  return (
    <CenteredColumn style={{ zIndex: '10' }}>
      <div
        style={{
          background: 'rgba(255, 255, 255, 0.75)',
          height: '100%',
          width: '100%',
        }}
      >
        <div
          style={{
            background: '#FFFFFF',
            border: '2px solid black',
            height: '100%',
            padding: '20px',
            width: '50%',
          }}
        >
          <DivTitle
            style={{
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
              margin: '-4px 0 0 0',
              padding: '0',
              width: '100%',
            }}
          >
            <span>FAQ</span>
            <button
              type="button"
              style={{
                background: 'transparent',
                border: '0',
                cursor: 'pointer',
                padding: '0',
                fontSize: '2rem',
              }}
              onClick={() => {
                setIsShowingFAQ(false);
              }}
            >
              &times;
            </button>
          </DivTitle>
          <ul
            style={{
              fontSize: '15px',
              listStyleType: 'none',
              margin: '0.25rem 0 0 0',
              padding: '0',
            }}
          >
            { questionItems }
          </ul>
        </div>
      </div>
    </CenteredColumn>
  );
}

FAQ.propTypes = {
  setIsShowingFAQ: PropTypes.func.isRequired,
};

export default FAQ;
