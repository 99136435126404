import React from 'react';
import PropTypes from 'prop-types';
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from 'react-simple-maps';
import * as S from '../styles';
import nationalMap from './national-map.json';

const getSmallestStateCount = (states) => {
  let min = Infinity;

  Object.keys(states).forEach((stateName) => {
    if (states[stateName] < min) {
      min = states[stateName];
    }
  });

  return min;
};

const getSecondLargestStateCount = (states) => {
  let largest = 0;
  let secondLargest = -1;

  Object.keys(states).forEach((stateName) => {
    const count = states[stateName];
    if (count > largest) {
      secondLargest = largest;
      largest = count;
    } else if (count > secondLargest && count < largest) {
      secondLargest = count;
    }
  });

  return secondLargest;
};

const getStateWithLargestCount = (states) => {
  let max = 0;
  let name = '';

  Object.keys(states).forEach((stateName) => {
    if (states[stateName] > max) {
      max = states[stateName];
      name = stateName;
    }
  });

  return name;
};

const calculateRadius = (
  stateName, stateCount, secondLargestCount, smallestCount, stateWithLargestCount,
) => {
  if (stateName === stateWithLargestCount) {
    return 15;
  }

  const range = secondLargestCount - smallestCount;
  const maxRadius = 10;

  for (let i = 0; i <= maxRadius; i++) {
    if (stateCount <= smallestCount + (range / maxRadius) * i) {
      return i;
    }
  }

  return maxRadius;
};

function MapChart(props) {
  const { states, dep } = props;
  const secondLargestCount = getSecondLargestStateCount(states.estados);
  const smallestCount = getSmallestStateCount(states.estados);
  const stateWithLargestCount = getStateWithLargestCount(states.estados);
  let color = '#000000';
  if (dep !== 'default') {
    color = S.worldMapColors[dep];
  }

  return (
    <div style={{ backgroundColor: '#FFFFFF' }}>
      <ComposableMap
        projection="geoMercator"
        projectionConfig={{
          scale: 400,
          center: [-50, -30],
        }}
        width={600}
        height={600}
      >
        <Geographies geography={nationalMap}>
          {({ geographies }) => {
            return (
              <>
                {geographies.map((geo) => {
                  return (
                    <Geography
                      key={`${geo.rsmKey}-Geography`}
                      stroke="#FFFFFF"
                      geography={geo}
                      fill="#CCCCCC"
                    />
                  );
                })}

                {geographies.map((geo) => {
                  const stateCount = states.estados[geo.properties.name];
                  const markerSize = calculateRadius(
                    geo.properties.name,
                    stateCount,
                    secondLargestCount,
                    smallestCount,
                    stateWithLargestCount,
                  );

                  return (
                    <g
                      key={`${geo.rsmKey}-Marker`}
                      style={{ pointerEvents: 'none' }}
                    >
                      <Marker coordinates={[geo.properties.longitude, geo.properties.latitude]}>
                        <circle r={markerSize} fill={color} opacity="1" />
                      </Marker>
                    </g>
                  );
                })}
              </>
            );
          }}
        </Geographies>
      </ComposableMap>
    </div>
  );
}

MapChart.propTypes = {
  dep: PropTypes.string,
  states: PropTypes.array.isRequired,
};

MapChart.defaultProps = {
  dep: 'default',
};

export default MapChart;
