import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as S from '../styles';
import StackedBar from '../charts/bar';
import 'rc-slider/assets/index.css';
import { dict } from '../dict';
import Loading from './loading';

function BarChartFau(props) {
  const { getCountDashboard } = props;
  const [allCategories, setAllCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [barData, setBarData] = useState(null);
  const [allCount, setAllCount] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setCategories(allCategories);
  }, [allCategories]);

  useEffect(() => {
    const data = {
      labels: ['FAU'],
      datasets: categories.map((category) => {
        return {
          label: dict(category),
          data: [],
          category,
        };
      }),
    };

    data.datasets.forEach((dataset, index) => {
      data.labels.forEach((label) => {
        dataset.backgroundColor = S.barColors[label][index % 15];
        dataset.data.push(allCount[label][dataset.category]);
      });
    });

    setBarData(data);
  }, [categories]);

  useEffect(() => {
    const count = { FAU: {} };
    getCountDashboard().then((res) => {
      count.FAU = res;
      setAllCount(count);
      setAllCategories(['Produção Artística', 'Produção Técnica', 'Produção Bibliográfica',
        'Orientação', 'Bancas', 'Prêmios e Títulos']);
      setIsLoading(false);
    });
  }, []);

  return (
    <BarChartContainer>
      {
        isLoading
          ? <Loading />
          : (
            <>
              <StackedBar data={barData} type="single" />
            </>
          )
      }
    </BarChartContainer>
  );
}

BarChartFau.propTypes = {
  getCountDashboard: PropTypes.func.isRequired,
};

const BarChartContainer = styled.div`
    background-color: white;
    padding: 25px;
`;

export default BarChartFau;
