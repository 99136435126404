import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getImg } from '../api';
import { DivDep } from '../styles';
import Loading from './loading';
import imageAvatar from '../assets/lattes_avatar_preto.png';

function Photo(props) {
  const { id } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [photoURL, setPhotoURL] = useState(null);

  useEffect(() => {
    getImg(id).then((url) => {
      const auxImg = document.createElement('img');
      auxImg.src = url;
      auxImg.addEventListener('load', () => {
        setIsLoading(false);
        if (auxImg.naturalWidth === 95 && auxImg.naturalHeight === 99) {
          setPhotoURL(imageAvatar);
        } else {
          setPhotoURL(url);
        }
      });
    });
  }, []);

  const activeDepartmentAnchor = [...document.querySelectorAll('a[id^=AU]')]
    .find((x) => {
      return x.style.backgroundColor;
    });

  const color = activeDepartmentAnchor.style.backgroundColor;
  const photoDivStyle = {
    backgroundImage: `url(${photoURL})`,
    backgroundPosition: 'center top',
    border: `8px solid ${color}`,
    borderRadius: '50%',
    fontSize: 12,
  };

  if (photoURL === imageAvatar) {
    photoDivStyle.backgroundPosition = 'center 0px';
  }

  return (
    <DivDep style={isLoading ? {} : photoDivStyle}>
      {isLoading && (
        <Loading />
      )}
    </DivDep>
  );
}

Photo.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Photo;
