import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'rc-slider/assets/index.css';
import styled from 'styled-components';
import MapChart from './national-map';
import Loading from './loading';

function MapProducaoNacional(props) {
  const { getMap } = props;
  const [map, setMap] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getMap().then((res) => {
      setMap(res);
      setIsLoading(false);
    });
  }, []);

  return (
    <MapContainer>
      {
        isLoading
          ? <Loading />
          : <MapChart states={map} />
      }
    </MapContainer>
  );
}

MapProducaoNacional.propTypes = {
  getMap: PropTypes.func.isRequired,
};

const MapContainer = styled.div`
    background-color: white;
`;

export default MapProducaoNacional;
