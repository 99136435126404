import React from 'react';
import { setColorDocentes } from '../components/btnFunctions';
import DocentesList from '../components/DocentesList';

function Docentes() {
  return (
    <DocentesList
      department="all"
      setColorDocentes={setColorDocentes}
    />
  );
}

export default Docentes;
