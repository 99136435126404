import React, { useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { setColorDepAUP } from '../components/btnFunctions';
import {
  getDashboardCount, getDashboardCountByDep, getDashboard,
  getDashboardByDep, getDashboardKeywords, getDashboardMap,
  getDashboardMapNational,
} from '../api';
import {
  Conteudo, DivCard, DivInfo, DivGraph, DivInfoText, DivDep, DivInfoProf, departmentColors,
} from '../styles';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import LineChartDepartamento from '../components/chart-line-departamento';
import BarChartDepartamento from '../components/chart-bar-departamento';
import KeywordsDepartamento from '../components/keywords-departamento';
import MapDepartamento from '../components/map-departamento';
import MapDepartamentoNacional from '../components/map-departamento-nacional';

function AUP() {
  useEffect(() => {
    setColorDepAUP();
  }, []);
  return (

    <Conteudo style={{ justifyContent: 'start' }}>
      <DivCard>
        <DivInfo>
          <DivDep
            style={{
              backgroundColor: departmentColors.AUP,
              color: '#fff',
            }}
          >
            AUP
          </DivDep>
          <DivInfoProf>
            <span style={{ fontSize: 25 }}>Departamento de Projeto</span>
            <span>Chefe</span>
            <span>Prof. Dr. Luís Antônio Jorge</span>
            <span>Vice-Chefe</span>
            <span>Prof. Dr. Fábio Mariz Gonçalves</span>
          </DivInfoProf>
        </DivInfo>
        <DivGraph>
          <Carousel
            showArrows
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
            autoPlay
            infiniteLoop
            interval={5000}
          >
            <div>
              <KeywordsDepartamento
                dep="AUP"
                getKeywords={getDashboardKeywords}
              />
            </div>
            <div>
              <MapDepartamento
                dep="AUP"
                getMap={getDashboardMap}
              />
            </div>
            <div>
              <MapDepartamentoNacional
                dep="AUP"
                getMap={getDashboardMapNational}
              />
            </div>
            <div>
              <BarChartDepartamento
                dep="AUP"
                getCountDashboard={getDashboard}
                getCountByDep={getDashboardByDep}
              />
            </div>
            <div>
              <LineChartDepartamento
                dep="AUP"
                getDashboardCount={getDashboardCount}
                getDepCount={getDashboardCountByDep}
              />
            </div>
          </Carousel>
        </DivGraph>
        <DivInfoText>
          <span
            style={{
              fontSize: 25,
              fontWeight: 'bold',
            }}
          >
            Histórico
          </span>
          <span>
            O Departamento de Projeto – AUP foi fundado em 1962, sendo
            originado a partir da sequência de disciplinas de Composição, a
            partir de reunião dos colegiados João Baptista Vilanova Artigas,
            Roberto Cerqueira César, Abelardo Riedy de Souza e Hélio de Queiroz
            Duarte.
          </span>
          <span>
            Tem como objetivo central atuar com os demais departamentos da
            FAUUSP na formação de arquitetos, urbanistas e designers nos cursos
            de graduação, e de professores e pesquisadores na pós-graduação.
          </span>
          <span>
            Propõe-se, neste sentido, desenvolver a capacidade de compreender,
            interpretar criticamente e reelaborar os espaços, objetos e artefatos
            visuais que dão suporte ao processo social do país, de modo a fazer
            avançar não apenas a tecnologia, mas também os modos socioeconômicos
            de organização, na direção da universalização da qualidade de vida
            para todos seus habitantes.
          </span>
          <span>
            No âmbito da graduação, o centro das atividades é voltado para a prática
            do projeto, tanto na escala do planejamento urbano e regional, como
            da arquitetura do edifício, da paisagem e do ambiente, do objeto e da
            programação visual, tendo a atividade de desenvolvimento de trabalhos
            práticos – assim como a reflexão a eles associada – utilizando para
            tal os studios e salas de aula, assim como imersões no campo, nos
            territórios objeto de intervenção/reflexão.
          </span>
          <span>
            Na pós-graduação, desenvolvem-se estudos e pesquisas visando a compreensão
            das práticas projetuais assim como dos processos sociais, econômicos
            políticos e tecnológicos a ela atinentes.
          </span>
        </DivInfoText>
      </DivCard>
    </Conteudo>
  );
}

export default AUP;
