import React from 'react';
import { setColorDocentesAUT } from '../components/btnFunctions';
import DocentesList from '../components/DocentesList';

function DocentesAUT() {
  return (
    <DocentesList
      department="AUT"
      setColorDocentes={setColorDocentesAUT}
    />
  );
}

export default DocentesAUT;
