import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as S from '../styles';
import StackedBar from '../charts/bar';
import 'rc-slider/assets/index.css';
import { dict } from '../dict';
import Loading from './loading';

function BarChartDocenteProducao(props) {
  const {
    dep, id, getCountDocente, getTipos,
  } = props;
  const [allCategories, setAllCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [barData, setBarData] = useState(null);
  const [allCount, setAllCount] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setCategories(allCategories);
  }, [allCategories]);

  useEffect(() => {
    const data = {
      labels: ['DOCENTE'],
      datasets: categories.map((category) => {
        return {
          label: dict(category),
          data: [],
          category,
        };
      }),
    };

    data.datasets.forEach((dataset, index) => {
      data.labels.forEach((label) => {
        dataset.backgroundColor = S.barColors[dep][index % 6];
        dataset.data.push(allCount[label][dataset.category]);
      });
    });

    setBarData(data);
  }, [categories]);

  useEffect(() => {
    const count = { DOCENTE: {} };
    getTipos({ id }).then((res) => {
      getCountDocente({ id, tipos: res }).then((resDoc) => {
        count.DOCENTE = resDoc;
        setAllCount(count);
        setAllCategories(res);
        setIsLoading(false);
      });
    });
  }, []);

  return (
    <BarChartContainer>
      {isLoading
        ? <Loading />
        : (
          <>
            <StackedBar data={barData} type="single" />
          </>
        )}
    </BarChartContainer>
  );
}

BarChartDocenteProducao.propTypes = {
  dep: PropTypes.string.isRequired,
  getCountDocente: PropTypes.func.isRequired,
  getTipos: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

const BarChartContainer = styled.div`
    background-color: white;
    padding: 25px;
`;

export default BarChartDocenteProducao;
