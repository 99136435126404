import React from 'react';
import ProducaoFau from '../components/producao-fau';
import {
  getProdTecCount,
  getProdTecKeywords,
  getProdTecMap,
  getProdTecMapNational,
  getProdTecTipos,
} from '../api';
import { ProdTecDescription } from '../staticTexts';
import { setColorProducaoT } from '../components/btnFunctions';

function ProducaoTecnica() {
  return (
    <ProducaoFau
      getCount={getProdTecCount}
      getKeywords={getProdTecKeywords}
      getMap={getProdTecMap}
      getNationalMap={getProdTecMapNational}
      getTypes={getProdTecTipos}
      infoText={ProdTecDescription}
      productionType="Produção Técnica"
      setMenuItemColor={setColorProducaoT}
    />
  );
}

export default ProducaoTecnica;
