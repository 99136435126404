import React from 'react';
import { setColorDocentesAUH } from '../components/btnFunctions';
import DocentesList from '../components/DocentesList';

function DocentesAUH() {
  return (
    <DocentesList
      department="AUH"
      setColorDocentes={setColorDocentesAUH}
    />
  );
}

export default DocentesAUH;
