import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import styled from 'styled-components';
import Range from 'rc-slider/lib/Range';
import * as S from '../styles';

const MIN_YEAR = 2010;
const MAX_YEAR = new Date().getFullYear();

function LineChart(props) {
  const {
    cachedData, formatedData, changeLabels, dep,
  } = props;
  const [yearsFiltered, setYearsFiltered] = useState([2014, MAX_YEAR]);
  const [lineData, setLineData] = useState(null);

  useEffect(() => {
    if (formatedData) {
      setLineData(formatedData);
      return;
    }
    const data = {
      labels: [],
      datasets: [],
    };
    const minYear = yearsFiltered[0]; const
      maxYear = yearsFiltered[1];
    const years = Array(maxYear - minYear + 1).fill();
    years.forEach((_, i) => {
      const year = minYear + i;
      data.labels = [...data.labels, year];
    });
    let departments = [];
    if (changeLabels === 'normal') {
      departments = ['AUH', 'AUT', 'AUP'];
      departments.forEach((department) => {
        const dataset = {
          label: department,
          data: [],
          fill: false,
          borderColor: S.departmentColors[department],
          backgroundColor: S.departmentColors[department],
        };
        years.forEach((_, i) => {
          if (cachedData) {
            dataset.data = [...dataset.data, cachedData[department][minYear + i]];
          } else {
            dataset.data = [...dataset.data, 0];
          }
        });
        data.datasets = [...data.datasets, dataset];
      });
    } else if (changeLabels === 'fau') {
      const dataset = {
        label: 'FAU',
        data: [],
        fill: 'origin',
        borderColor: S.departmentColors.FAU,
        backgroundColor: S.departmentColors.FAU,
      };
      years.forEach((_, i) => {
        if (cachedData) {
          dataset.data = [...dataset.data, cachedData.FAU[minYear + i]];
        } else {
          dataset.data = [...dataset.data, 0];
        }
      });
      data.datasets = [...data.datasets, dataset];
    } else if (changeLabels === 'docente') {
      departments = ['DOCENTE', dep];
      const dataset = {
        label: 'DOCENTE',
        data: [],
        fill: 'origin',
        borderColor: S.departmentColors.DOCENTE,
        backgroundColor: S.departmentColors.DOCENTE,
      };
      years.forEach((_, i) => {
        if (cachedData) {
          dataset.data = [...dataset.data, cachedData.DOCENTE[minYear + i]];
        } else {
          dataset.data = [...dataset.data, 0];
        }
      });
      data.datasets = [...data.datasets, dataset];

      const datasetDep = {
        label: departments[1],
        data: [],
        fill: '-1',
        borderColor: S.departmentColors[departments[1]],
        backgroundColor: S.departmentColors[departments[1]],
      };
      years.forEach((_, i) => {
        if (cachedData) {
          datasetDep.data = [...datasetDep.data, cachedData[departments[1]][minYear + i]];
        } else {
          datasetDep.data = [...datasetDep.data, 0];
        }
      });
      data.datasets = [...data.datasets, datasetDep];
    } else {
      departments = [dep, 'FAU'];
      const datasetDep = {
        label: departments[0],
        data: [],
        fill: 'origin',
        borderColor: S.departmentColors[departments[0]],
        backgroundColor: S.departmentColors[departments[0]],
      };
      years.forEach((_, i) => {
        if (cachedData) {
          datasetDep.data = [...datasetDep.data, cachedData[departments[0]][minYear + i]];
        } else {
          datasetDep.data = [...datasetDep.data, 0];
        }
      });
      data.datasets = [...data.datasets, datasetDep];

      const dataset = {
        label: 'FAU',
        data: [],
        fill: '-1',
        borderColor: S.departmentColors.FAU,
        backgroundColor: S.departmentColors.FAU,
      };
      years.forEach((_, i) => {
        if (cachedData) {
          dataset.data = [...dataset.data, cachedData.FAU[minYear + i]];
        } else {
          dataset.data = [...dataset.data, 0];
        }
      });
      data.datasets = [...data.datasets, dataset];
    }
    setLineData(data);
  }, [yearsFiltered, cachedData, formatedData]);

  const options = {
    legend: { display: true },
    maintainAspectRatio: true,
    responsive: false,
    elements: {
      line: {
        tension: 0,
      },
    },
  };

  return lineData && (
    <>
      <Slider>
        <span>{MIN_YEAR}</span>
        <Range
          min={MIN_YEAR}
          max={MAX_YEAR}
          defaultValue={[yearsFiltered[0], yearsFiltered[1]]}
          allowCross={false}
          onChange={setYearsFiltered}
        />
        <span>{MAX_YEAR}</span>
      </Slider>
      <Years>
        <span>de</span>
        <span><b>{yearsFiltered[0]}</b></span>
        <span>a</span>
        <span><b>{yearsFiltered[1]}</b></span>
      </Years>

      <Line data={lineData} options={options} width={450} height={200} />
    </>
  );
}

LineChart.propTypes = {
  cachedData: PropTypes.object,
  formatedData: PropTypes.object,
  changeLabels: PropTypes.string,
  dep: PropTypes.string,
};

LineChart.defaultProps = {
  cachedData: null,
  formatedData: null,
  changeLabels: 'normal',
  dep: null,
};

export default LineChart;

const Slider = styled.div`
    width: 450px;
    justify-content: center;
    padding-top: 8px;
    display: flex;
    flex-direction: row;
    > span {
        :nth-child(1) { padding-right: 12px }
        :nth-child(3) { padding-left: 12px }
        font-size: 14px;
        margin-top: -3px;
        opacity: 0.5;
    }
    color: #000;
    font-family: Sans-Serif;
    .rc-slider-track {
        background-color: #000;
    }
    .rc-slider-handle {
        background-color: #000;
        border-color: #000;
    }
    .rc-slider-handle:hover {
        border-color: #000;
    }
    .rc-slider-handle:focus {
        border-color: #000;
    }
    .rc-slider-handle:active {
        border-color: #000;
        box-shadow: 0 0 5px #000;
    }
    .rc-slider-rail {
        background-color: #cccccc;
    }

`;
const Years = styled.div`
    width: 450px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 8px 0px 8px;
    font-size: 20px;
    > span {
        :nth-child(2) {
            padding: 0px 8px;
        }
        :nth-child(4) {
            padding-left: 8px;
        }
    }
`;
