import React from 'react';
import PropTypes from 'prop-types';
import {
  ComposableMap, Geographies, Geography, Marker,
} from 'react-simple-maps';
import * as S from '../styles';
import geoMap from './world-map.json';

function MapChart(props) {
  const { countries, dep } = props;
  let max = 0;
  if (countries.length > 0) {
    const firstCountry = countries[0];
    const firstCountryName = Object.keys(firstCountry)[0];
    max = firstCountry[firstCountryName].total;
  }

  const maxRadius = 35;
  let color = '#000000';
  if (dep !== 'default') {
    color = S.worldMapColors[dep];
  }

  return (
    <ComposableMap viewBox="50 0 800 600" style={{ with: 510, height: 280 }}>
      <Geographies geography={geoMap}>
        {({ geographies }) => {
          return geographies.map((geo) => {
            return <Geography key={geo.rsmKey} geography={geo} fill="#CCCCCC" stroke="#D6D6D6" />;
          });
        }}
      </Geographies>

      {countries.map((ctr) => {
        const country = Object.keys(ctr)[0];
        if (country !== '') {
          const radius = Math.min(1000 * (ctr[country].total / max), maxRadius);
          return (
            <Marker
              key={country}
              coordinates={[ctr[country].long, ctr[country].lat]}
            >
              <circle r={radius} fill={color} opacity="1" />
            </Marker>
          );
        }

        return null;
      })}
    </ComposableMap>
  );
}

MapChart.propTypes = {
  countries: PropTypes.array.isRequired,
  dep: PropTypes.string,
};

MapChart.defaultProps = {
  dep: 'default',
};

export default MapChart;
