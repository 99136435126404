import React from 'react';
import ProducaoFau from '../components/producao-fau';
import {
  getProdBibCount,
  getProdBibKeywords,
  getProdBibMap,
  getProdBibMapNational,
  getProdBibTipos,
} from '../api';
import { ProdBibDescription } from '../staticTexts';
import { setColorProducaoB } from '../components/btnFunctions';

function ProducaoBibliografica() {
  return (
    <ProducaoFau
      getCount={getProdBibCount}
      getKeywords={getProdBibKeywords}
      getMap={getProdBibMap}
      getNationalMap={getProdBibMapNational}
      getTypes={getProdBibTipos}
      infoText={ProdBibDescription}
      productionType="Produção Bibliográfica"
      setMenuItemColor={setColorProducaoB}
    />
  );
}

export default ProducaoBibliografica;
