import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'rc-slider/assets/index.css';
import MapChart from './national-map';
import Loading from './loading';

function MapDocenteNacional(props) {
  const { id, getMap, dep } = props;
  const [map, setMap] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getMap({ id }).then((res) => {
      setMap(res);
      setIsLoading(false);
    });
  }, []);

  return (
    <div>
      {
        isLoading
          ? <Loading />
          : <MapChart states={map} dep={dep} />
      }
    </div>
  );
}

MapDocenteNacional.propTypes = {
  dep: PropTypes.string.isRequired,
  getMap: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default MapDocenteNacional;
