import React, { useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { setColorDepAUH } from '../components/btnFunctions';
import {
  getDashboardCount, getDashboardCountByDep, getDashboard,
  getDashboardByDep, getDashboardKeywords, getDashboardMap,
  getDashboardMapNational,
} from '../api';
import {
  Conteudo, DivCard, DivInfo, DivGraph, DivInfoText, DivDep, DivInfoProf, departmentColors,
} from '../styles';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import LineChartDepartamento from '../components/chart-line-departamento';
import BarChartDepartamento from '../components/chart-bar-departamento';
import KeywordsDepartamento from '../components/keywords-departamento';
import MapDepartamento from '../components/map-departamento';
import MapDepartamentoNacional from '../components/map-departamento-nacional';

function AUH() {
  useEffect(() => {
    setColorDepAUH();
  }, []);

  return (
    <Conteudo style={{ justifyContent: 'start' }}>
      <DivCard>
        <DivInfo>
          <DivDep style={{ backgroundColor: departmentColors.AUH }}>
            AUH
          </DivDep>
          <DivInfoProf>
            <span style={{ fontSize: 25 }}>
              Departamento de História de Arquitetura e Estética do Projeto
            </span>
            <span>Chefe</span>
            <span>Prof. Dr. Leandro Silva Medrano</span>
            <span>Vice-Chefe</span>
            <span>Profa. Dra. Beatriz Piccolotto Siqueira Bueno</span>
          </DivInfoProf>
        </DivInfo>
        <DivGraph>
          <Carousel
            showArrows
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
            autoPlay
            infiniteLoop
            interval={5000}
          >
            <div>
              <KeywordsDepartamento
                dep="AUH"
                getKeywords={getDashboardKeywords}
              />
            </div>
            <div>
              <MapDepartamento
                dep="AUH"
                getMap={getDashboardMap}
              />
            </div>
            <div>
              <MapDepartamentoNacional
                dep="AUH"
                getMap={getDashboardMapNational}
              />
            </div>
            <div>
              <BarChartDepartamento
                dep="AUH"
                getCountDashboard={getDashboard}
                getCountByDep={getDashboardByDep}
              />
            </div>
            <div>
              <LineChartDepartamento
                dep="AUH"
                getDashboardCount={getDashboardCount}
                getDepCount={getDashboardCountByDep}
              />
            </div>
          </Carousel>
        </DivGraph>
        <DivInfoText>
          <span
            style={{
              fontSize: 25,
              fontWeight: 'bold',
            }}
          >
            Histórico
          </span>
          <span>
            A articulação do Departamento de História da Arquitetura e Estética
            do Projeto, em 1962, deu-se pela aglutinação administrativa e
            programação conjunta das antigas disciplinas de arquitetura analítica,
            teoria da arquitetura, economia política-estatística-administração,
            arquitetura no Brasil e história da arte-estética. Essa iniciativa
            pioneira deve ser entendida como momento de inflexão de processos
            culturais desencadeados no seio da Escola desde a fundação, em 1948.
            Tratava-se da superação, no que respeita à política de ensino, dos
            limites cognitivos e práticos inerentes à concepção de arquitetura e
            do trabalho profissional mantida nas escolas Politécnica e de Belas
            Artes, origem de quase todos os primeiros docentes da FAUUSP. No que
            diz respeito às disciplinas, atrás arroladas, as maiores deficiências
            sentidas eram o anacronismo temático, as tendências reducionistas próprias
            a uma visão puramente pragmática do ensino e, de modo decisivo, a
            metodologia formalista-segmentar no exame histórico da arquitetura ou
            meramente descritivo-ilustrativo do trato da história em geral.
          </span>
          <span>
            Com a fundação do Departamento de História da Arquitetura e Estética
            do Projeto – AUH deu-se o início de um processo de ampla renovação,
            desde seus fundamentos, dos processos de ensino e pesquisa. Presidiu
            essa renovação a consciência da necessidade de formação de seus quadros
            e com esse desígnio foram organizados os primeiros cursos de pós-graduação.
            Desde então houve empenho do departamento na estruturação da carreira
            universitária e, atualmente(2016), o departamento conta com 27 (vinte
            e sete) professores, assim discriminados: 04 (quatro) titulares, 08
            (oito) associados e 15 (quinze) doutores.
          </span>
        </DivInfoText>
      </DivCard>
    </Conteudo>
  );
}

export default AUH;
