import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import LogoFAU from '../assets/fau.png';

function Header() {
  return (
    <Container>
      <Text to="/">
        <Link to="/" className="title">FAU Aberta </Link>
        <Link to="/" className="sub-title">| Produção Intelectual da FAUUSP</Link>
      </Text>
      <Link
        to="/"
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <img
          className="logo-header"
          src={LogoFAU}
          width={138}
          height={60}
          alt="Logo FAU"
        />
      </Link>
    </Container>
  );
}

export default Header;

const Container = styled.div`
    display: grid;
    overflow: hidden;
    width: 100%;
    grid-template-columns: 1fr 35%
    grid-row-start:1;
    grid-column-start:3;
    .logo-header{
        
    display: flex;
    justify-content: flex-end;

    }
    @media screen and (max-width: 800px) {
        .sub-title {
          display: none;
        }
        .logo-header{
            width: 115px;
            height: 50px;
        }

`;
const Text = styled.div`
    display: flex;
    font-family: sans-serif;
    grid-column-start: 1;
    .title { 
        font-size: 30px;
        color: #000;
        font-weight: bold;
        
    }
    .sub-title { 
        font-size: 26px;
        color: #000;
        margin-left:9px;
    }
    > a {
        display:flex;
        font-size: 20px;
        align-items:center;
        text-decoration: none !important;
    }


      }
`;
