import styled from 'styled-components';

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 64px;
`;
const CenteredColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    grid-row-start: 2;
    grid-column-start: 3;
`;
const Body = styled.div`
    display: grid;
    width:100%
    height: 99.1vh;
    grid-template-columns: 1fr 230px 1100px 1fr;
    grid-template-rows: 100px 590px 100px
    font-family:  sans-serif;
    scrollbar-color: #fff #000;
    *,
*::before,
*::after {
    box-sizing: border-box;
}
`;
const Row = styled.div`
    display: flex;
    > div:not(:last-child) {
        margin-right: 64px;
    }
    margin-bottom: 32px;
`;
const TextBox = styled.div`
    width: 100%;
    grid-template-columns: 550px 548px;
    background-color: #fff;
    display: grid;
    font-family: sans-serif;
    align-items: flex-start;
    flex-direction: column;
    > span {
        opacity: 1;
        font-size: 14.5px;
        line-height: 1.5;
        grid-column-start: 2
        :not(:last-child) {
            margin-bottom: 5px;
        }
    }
`;
const TextArea = styled.div`
    width: 100%;
    max-width: 1280px;
    font-family:  sans-serif;
`;
const Title = styled.span`
    font-size: 48px;
    padding-bottom: 32px;
    font-family:  sans-serif;
`;
const SubTitle = styled.span`
    font-size: 28px;
    margin: 32px 0px 32px;
    font-family:  sans-serif;
`;
const Close = {
  textDecoration: 'none',
  color: '#000',
  cursor: 'pointer',
  fontSize: '20px',
  fontFamily: 'cursive',
  position: 'absolute',
  right: '30px',

};
const primary = '#383838';
const secondary = '#878787';
const departmentColors = {
  AUH: '#e8d313',
  AUT: '#496aaa',
  AUP: '#D23a47',
  FAU: '#cccccc',
  DOCENTE: '#000000',
};
const categoriesColors = [
  '#b8730b',
  '#0c7a00',
  '#42ffb7',
  '#0f40d4',
  '#97a0de',
  '#b017e8',
  '#f07c1d',
  '#997fdb',
  '#69283f',
  '#778078',
  '#998f87',
  '#b5b5b5',
  '#2e0202',
  '#235f70',
  '#a0ab3c',
];
const Conteudo = styled.div`
grid-column-start: 3;
grid-row-start:2
`;

const DivCard = styled.div`
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: grid;
    border: 1px solid #000;
    grid-template-columns 550px 548px;
    grid-template-rows 40% 60%;
    position: relative
    }
   
    
`;
const DivInfo = styled.div`
display: grid;
grid-row-start: 1;
grid-column-end: 2;
height: 100%;
grid-template-columns 193px 1fr;

`;
const DivInfoProducao = styled.div`
display: grid;
grid-row-start: 1;
grid-column-end: 2;
height: 100%;

`;
const DivTotal = styled.div`
grid-row-start: 2;
width: 100%;
padding-top: 20px;
padding-left: 20px;
padding-top: 20px;
display: flex;
flex-flow: column;
> span {
    font-size: 25px;
    font-weight: bold;
}

`;
const DivTitle = styled.div`
width: 90%;
padding-top: 20px;
padding-left: 20px;
display: flex;
flex-flow: column;
> span {
    font-size: 25px;
    font-weight: bold;
}
`;
const DivGraph = styled.div`
grid-row-start: 2;
padding: 20px 20px 0px 20px
width:100%
height: 100%;
.carousel.carousel-slider, .carousel-root{
    height: 100%
}
.carousel .slide{
    background: #fff;
}
.carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
}
.carousel .control-next.control-arrow:before{
    border-left: 15px solid #000;
}
.carousel .control-prev.control-arrow:before{
    border-right: 15px solid #000;
}
.carousel .control-arrow, .carousel.carousel-slider .control-arrow{
    opacity: 1;
    margin-left: -10px;
    margin-right: -10px;
}
.carousel.carousel-slider .control-arrow:hover{
    background: rgba(0,0,0,0)
}
`;

const DivInfoText = styled.div`
grid-row-end: 3;
grid-column-start: 2;
grid-row-start: 1;
padding: 20px;
display: flex;
flex-direction: column;
overflow-x: hidden
>span {
    :nth-child(2){
        margin-top: -10px;
    }
    margin-bottom: 10px;
    font-size: 13.5px;
    line-height: 1.5
}

`;
const DivDep = styled.div`
    margin: 20px;
        display: flex;
        border-radius: 50%;
        width: 150px;
        height: 150px;
        justify-content: center;
        align-items:center;
        font-size: 26px;
        font-weight: bold;
        background-repeat: no-repeat;
        background-size: 138px auto;
        background-position:center;
        background-position-y: -7.5px;
`;
const DivInfoProf = styled.div`
width: 90%;
padding-top: 20px;
display: flex;
flex-flow: column;
a{
    font-weight: bold;
    text-decoration: none
    color: #000;
    font-size: 15px;
}
 >span:nth-child(odd){
     font-weight: bold;
     font-size: 16px;
     
 }
 >span:nth-child(even){
    font-size: 15px;
    margin-top: 13px;
margin-bottom: 3px;
    color: #000;
}

`;
const DivLink = styled.div`
>div{
display:flex
flex-direction: column;
flex-overflow: column;
>span#ano{
    font-weight: bold;
    font-size: 25px;
}
div>span {
    margin-bottom: 5px;
}
}
`;
const DivLinkDocentes = styled.div`
    width: 100%
    background-color: #fff;
    display: flex;
    flex-wrap:wrap;
    align-items: flex-start;
    flex-direction: row;
    > span {
        font-size: 15px;
        font-weight: bold;
        margin-right:20px;
        opacity: 1
        :not(:last-child) {
            margin-bottom: 3.7px;
        }
        a {
            text-decoration: none;
            color: #000;
            font-size: 14px;
            font-weight: bold;
            opacity: 0.8;
            margin-bottom: 3.7px;
        }
    }
`;
const Container = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
grid-column-start: 2;
grid-row-start: 2;
a:hover {
    background-color: #000;
    color: #ffff;
}
a:hover#AUH{
    background-color: ${departmentColors.AUH};
    color: #000 !important;
    border: 1px solid #fff !important;

}
a:hover#AUT{
    background-color: ${departmentColors.AUT};
    color: #ffff !important; 
}
a:hover#AUP{
    background-color: ${departmentColors.AUP};
    color: #ffff !important;

}

> a{
    height: 40px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border 1px solid;
    width: 200px;
    cursor: pointer;
    text-align: center;
    color: #000 ;
    font-size: 15px;
    text-decoration: none;
    
}
a:not(:last-child){
    margin-bottom: 15px
}
`;

const keywordsCarousel = {
  backgroundColor: 'white',
  height: '100%',
};

const mapCarousel = {
  backgroundColor: 'white',
  height: '100%',
};

const barColors = {
  AUH: ['#605219', '#a0891c', '#d1b822', '#e8ce34', '#ffec3e', '#fdf39a'],
  AUT: ['#1a3156', '#33517c', '#4869a9', '#549ad3', '#81bbea', '#9cc1df'],
  AUP: ['#561a2e', '#7a2235', '#ad2d49', '#dd4062', '#f4607f', '#f27b94'],
  FAU: ['#000', '#4d4d4d', '#686868', '#808080', '#b3b3b3', '#e6e6e6'],
  DOCENTE: ['#000', '#4d4d4d', '#686868', '#808080', '#b3b3b3', '#e6e6e6'],
};

const wordCloudColors = {
  AUH: ['#6d6d6d', '#585859', '#353535', '#000'],
  AUP: ['#6d6d6d', '#585859', '#353535', '#000'],
  AUT: ['#6d6d6d', '#585859', '#353535', '#000'],
  default: ['#6d6d6d', '#585859', '#353535', '#000'],
};

const worldMapColors = {
  AUH: '#000000',
  AUP: '#000000',
  AUT: '#000000',
};
export {
  Grid,
  CenteredColumn,
  Body,
  Row,
  primary,
  secondary,
  TextBox,
  TextArea,
  departmentColors,
  Title,
  SubTitle,
  categoriesColors,
  Close,
  barColors,
  wordCloudColors,
  Conteudo,
  DivCard,
  DivInfo,
  DivGraph,
  DivInfoText,
  DivDep,
  DivInfoProf,
  DivLink,
  DivInfoProducao,
  DivTotal,
  DivTitle,
  DivLinkDocentes,
  Container,
  keywordsCarousel,
  mapCarousel,
  worldMapColors,
};
