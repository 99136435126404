import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import Header from './components/header';
import { Body } from './styles';
import Menu from './components/menu';
import Footer from './components/footer';
import Docentes from './pages/Docentes';
import AUH from './pages/AUH';
import AUP from './pages/AUP';
import AUT from './pages/AUT';
import DocentesAUH from './pages/DocentesAUH';
import DocentesAUP from './pages/DocentesAUP';
import DocentesAUT from './pages/DocentesAUT';
import PerfilDocente from './pages/PerfilDocente';
import ProducaoBibliografica from './pages/ProducaoBibliografica';
import ProducaoArtistica from './pages/ProducaoArtistica';
import ProducaoTecnica from './pages/ProducaoTecnica';
import Orientacoes from './pages/Orientacoes';
import Bancas from './pages/Bancas';
import PremiosETitulos from './pages/PremiosETitulos';
import PerfilProdBiblio from './pages/PerfilProdBiblio';
import PerfilProdArt from './pages/PerfilProdArt';
import PerfilProdTec from './pages/PerfilProdTec';
import PerfilOrientacoes from './pages/PerfilOrientacoes';
import PerfilBancas from './pages/PerfilBancas';
import PerfilPremios from './pages/PerfilPremios';
import FAQ from './components/FAQ';

function App() {
  const [isShowingFAQ, setIsShowingFAQ] = useState(false);

  return (
    <Router>
      <Body>
        <Header />
        <Menu setIsShowingFAQ={setIsShowingFAQ} />

        {isShowingFAQ && (
          <FAQ setIsShowingFAQ={setIsShowingFAQ} />
        )}

        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/pessoa/nomes" component={Docentes} />
          <Route path="/AUH" component={AUH} />
          <Route path="/pessoa/nome/auh" component={DocentesAUH} />
          <Route path="/AUP" component={AUP} />
          <Route path="/pessoa/nome/aup" component={DocentesAUP} />
          <Route path="/AUT" component={AUT} />
          <Route path="/pessoa/nome/aut" component={DocentesAUT} />
          <Route path="/pessoa/perfil/:id" component={PerfilDocente} />
          <Route path="/producao-bibliografica" component={ProducaoBibliografica} />
          <Route path="/pessoa/ProduçãoBibliográfica/:id" component={PerfilProdBiblio} />
          <Route path="/producao-artistica" component={ProducaoArtistica} />
          <Route path="/pessoa/ProduçãoArtística/:id" component={PerfilProdArt} />
          <Route path="/producao-tecnica" component={ProducaoTecnica} />
          <Route path="/pessoa/ProduçãoTécnica/:id" component={PerfilProdTec} />
          <Route path="/bancas" component={Bancas} />
          <Route path="/pessoa/Bancas/:id" component={PerfilBancas} />
          <Route path="/orientacoes" component={Orientacoes} />
          <Route path="/pessoa/Orientações/:id" component={PerfilOrientacoes} />
          <Route path="/premios" component={PremiosETitulos} />
          <Route path="/pessoa/PrêmioseTítulos/:id" component={PerfilPremios} />
        </Switch>
        <Footer />
      </Body>
    </Router>
  );
}

export default App;
