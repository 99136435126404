import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'rc-slider/assets/index.css';
import WordCloud from './word-cloud';
import Loading from './loading';

function KeywordsDocente(props) {
  const { id, getKeywords, dep } = props;
  const [words, setWords] = useState([]);
  const [isLoadingWords, setIsLoadingWords] = useState(true);

  useEffect(() => {
    getKeywords({ id, limit: 50 }).then((res) => {
      setWords(res.map((obj) => {
        return {
          text: Object.keys(obj)[0],
          value: Object.values(obj)[0],
        };
      }));
      setIsLoadingWords(false);
    });
  }, []);

  return (
    <div>
      {
        isLoadingWords
          ? <Loading />
          : <WordCloud words={words} dep={dep} />
      }
    </div>
  );
}

KeywordsDocente.propTypes = {
  dep: PropTypes.string.isRequired,
  getKeywords: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default KeywordsDocente;
