import React from 'react';
import ProducaoFau from '../components/producao-fau';
import {
  getProdArtCount,
  getProdArtKeywords,
  getProdArtMap,
  getProdArtMapNational,
  getProdArtTipos,
} from '../api';
import { ProdArtDescription } from '../staticTexts';
import { setColorProducaoA } from '../components/btnFunctions';

function ProducaoArtistica() {
  return (
    <ProducaoFau
      getCount={getProdArtCount}
      getKeywords={getProdArtKeywords}
      getMap={getProdArtMap}
      getNationalMap={getProdArtMapNational}
      getTypes={getProdArtTipos}
      infoText={ProdArtDescription}
      productionType="Produção Artística"
      setMenuItemColor={setColorProducaoA}
    />
  );
}

export default ProducaoArtistica;
