import React from 'react';
import PropTypes from 'prop-types';
import ReactWordCloud from 'react-wordcloud';
import * as S from '../styles';

const options = {
  colors: [],
  enableTooltip: false,
  deterministic: false,
  fontFamily: 'sans-serif',
  fontSizes: [10, 50],
  fontStyle: 'normal',
  fontWeight: '800',
  padding: 1,
  letterSpacing: -2,
  rotations: 0,
  rotationAngles: [0, 270],
  scale: 'sqrt',
  spiral: 'archimedean',
  transitionDuration: 700,
};

function WordCloud({ words, dep = 'default' }) {
  options.colors = S.wordCloudColors[dep];
  return (
    <div
      style={{
        height: 280,
        width: 510,
      }}
    >
      <ReactWordCloud options={options} words={words} />
    </div>
  );
}

WordCloud.propTypes = {
  dep: PropTypes.string,
  words: PropTypes.array.isRequired,
};

WordCloud.defaultProps = {
  dep: 'default',
};

export default WordCloud;
