import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'rc-slider/assets/index.css';
import styled from 'styled-components';
import WordCloud from './word-cloud';
import Loading from './loading';

function KeywordsProducao(props) {
  const { getKeywords } = props;
  const [words, setWords] = useState([]);
  const [isLoadingWords, setIsLoadingWords] = useState(true);

  useEffect(() => {
    getKeywords({ limit: 50 }).then((responsePayload) => {
      setWords(responsePayload.map((obj) => {
        return {
          text: Object.keys(obj)[0],
          value: Object.values(obj)[0],
        };
      }));
      setIsLoadingWords(false);
    });
  }, []);

  return (
    <KeywordsContainer>
      {
        isLoadingWords
          ? <Loading />
          : <WordCloud words={words} />
      }
    </KeywordsContainer>
  );
}

KeywordsProducao.propTypes = {
  getKeywords: PropTypes.func.isRequired,
};

const KeywordsContainer = styled.div`
    background-color: white;
    padding-bottom: 10px;
    height: 100%;
`;

export default KeywordsProducao;
