import React from 'react';
import ProducaoFau from '../components/producao-fau';
import { getPremiosCount } from '../api';
import { setColorPremio } from '../components/btnFunctions';
import { PremiosDescription } from '../staticTexts';

function PremiosETitulos() {
  return (
    <ProducaoFau
      getCount={getPremiosCount}
      infoText={PremiosDescription}
      isPremiosPage
      productionType="Prêmios e Títulos"
      setMenuItemColor={setColorPremio}
    />
  );
}

export default PremiosETitulos;
