import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import {
  Conteudo, DivCard, DivInfo, DivGraph, DivInfoText, DivInfoProf, keywordsCarousel,
  mapCarousel,
} from '../styles';
import {
  getPessoa, getKeywordsPessoa, getMapPessoa, getMapPessoaNational,
  getCountAllPessoa, getDashboardCountByDep,
} from '../api';
import Loading from '../components/loading';
import Photo from '../components/photo';
import setBorderMenu from '../components/btnFunctions';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import KeywordsDocente from '../components/keywords-docente';
import MapDocente from '../components/map-docente';
import MapDocenteNacional from '../components/map-docente-nacional';
import LineChartDocente from '../components/chart-line-docente';

function PerfilDocente() {
  const [data, setData] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    getPessoa({ id }).then((responsePayload) => {
      setData(responsePayload);
    });
  }, []);

  if (!data) {
    return (
      <Conteudo
        style={{
          alignItems: 'center',
          border: '0',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Loading />
      </Conteudo>
    );
  }

  return <Perfil data={data} id={id} />;
}

function Perfil(props) {
  const { data, id } = props;
  setBorderMenu(data.departamento);

  return (
    <Conteudo style={{ justifyContent: 'start', width: 1100 }}>
      <DivCard>
        <DivInfo>
          <Photo id={id} />
          <DivInfoProf>
            <span style={{ fontSize: 25 }}>{data.nome_completo}</span>
            <span>Telefone</span>

            {data.contato
              ? (
                <span>
                  +55 (11)
                  {data.contato}
                </span>
              )
              : <span>-</span>}
            <span>Currículo Lattes</span>
            <a
              href={`http://lattes.cnpq.br/${data.id_lattes}`}
              target="_blank"
              rel="noreferrer"
            >
              http://lattes.cnpq.br/
              {data.id_lattes}
            </a>
          </DivInfoProf>
        </DivInfo>

        <DivGraph>
          <Carousel
            showArrows
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
            autoPlay
            infiniteLoop
            interval={5000}
          >
            <div style={keywordsCarousel}>
              <KeywordsDocente
                id={data.id_lattes}
                getKeywords={getKeywordsPessoa}
                dep={data.departamento}
              />
            </div>
            <div style={mapCarousel}>
              <MapDocente
                id={data.id_lattes}
                getMap={getMapPessoa}
                dep={data.departamento}
              />
            </div>
            <div style={mapCarousel}>
              <MapDocenteNacional
                id={data.id_lattes}
                getMap={getMapPessoaNational}
                dep={data.departamento}
              />
            </div>
            <div style={mapCarousel}>
              <LineChartDocente
                dep={data.departamento}
                id={data.id_lattes}
                getDashboardCount={getDashboardCountByDep}
                getCountDocente={getCountAllPessoa}
              />
            </div>
          </Carousel>
        </DivGraph>
        <DivInfoText>
          <span
            style={{
              fontSize: 25,
              fontWeight: 'bold',
              marginTop: -3.8,
            }}
          >
            Resumo Acadêmico
          </span>
          <span>{data.resumo}</span>
        </DivInfoText>
      </DivCard>
    </Conteudo>
  );
}

Perfil.propTypes = {
  data: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
};

export default PerfilDocente;
