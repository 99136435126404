import { departmentColors } from '../styles';

export function resetMenu() {
  const link = document.querySelector('#links');
  link.querySelectorAll('a').forEach((a) => {
    a.style.backgroundColor = '';
    a.style.border = '1px solid';
    a.style.color = '';
  });
}

export function setColorMenu() {
  resetMenu();
  const btnFau = document.querySelector('#FAU');
  btnFau.style.backgroundColor = '#000';
  btnFau.style.border = 'none';
  btnFau.style.color = '#fff';
}

export function setColorDocentes() {
  const btnDocentes = document.querySelector('#DOCENTES');
  resetMenu();
  btnDocentes.style.backgroundColor = '#000';
  btnDocentes.style.color = '#fff';
  btnDocentes.style.border = 'none';
  document.querySelectorAll('a[id^=AU]').forEach((a) => {
    a.style.border = '2.5px solid';
    a.style.backgroundColor = '';
    a.style.color = '';
  });
}

export function setColorFAQ() {
  const btnFAQ = document.querySelector('#FAQ');
  resetMenu();
  btnFAQ.style.backgroundColor = '#000';
  btnFAQ.style.color = '#fff';
  btnFAQ.style.border = 'none';
}

export function setColorDepAUH() {
  const btnAUH = document.querySelector('#AUH');
  if (btnAUH.style.border !== '2.5px solid') {
    resetMenu();
  }
  btnAUH.style.backgroundColor = '#e8d313';
  btnAUH.style.color = '#000';
  btnAUH.style.border = 'none';
}

export function setColorDepAUT() {
  const btnAUT = document.querySelector('#AUT');
  if (btnAUT.style.border !== '2.5px solid') {
    resetMenu();
  }
  btnAUT.style.backgroundColor = '#4869a9';
  btnAUT.style.color = '#fff';
  btnAUT.style.border = 'none';
}

export function setColorDepAUP() {
  const btnAUP = document.querySelector('#AUP');
  if (btnAUP.style.border !== '2.5px solid') {
    resetMenu();
  }
  btnAUP.style.backgroundColor = '#D23a47';
  btnAUP.style.color = '#fff';
  btnAUP.style.border = 'none';
}

export function setColorDocentesAUH() {
  setColorDocentes();
  setColorDepAUH();
}

export function setColorDocentesAUT() {
  setColorDocentes();
  setColorDepAUT();
}

export function setColorDocentesAUP() {
  setColorDocentes();
  setColorDepAUP();
}

export function setColorProducaoB() {
  const btnPB = document.querySelectorAll('#PUBLICACAO')[0];
  if (btnPB.style.border !== '2.5px solid') {
    resetMenu();
  }
  btnPB.style.backgroundColor = '#000';
  btnPB.style.color = '#fff';
  btnPB.style.border = 'none';
}

export function setColorProducaoA() {
  const btnPA = document.querySelectorAll('#PUBLICACAO')[1];
  if (btnPA.style.border !== '2.5px solid') {
    resetMenu();
  }
  btnPA.style.backgroundColor = '#000';
  btnPA.style.color = '#fff';
  btnPA.style.border = 'none';
}

export function setColorProducaoT() {
  const btnPT = document.querySelectorAll('#PUBLICACAO')[2];
  if (btnPT.style.border !== '2.5px solid') {
    resetMenu();
  }
  btnPT.style.backgroundColor = '#000';
  btnPT.style.color = '#fff';
  btnPT.style.border = 'none';
}

export function setColorOrientacao() {
  const btnO = document.querySelectorAll('#PUBLICACAO')[3];
  if (btnO.style.border !== '2.5px solid') {
    resetMenu();
  }
  btnO.style.backgroundColor = '#000';
  btnO.style.color = '#fff';
  btnO.style.border = 'none';
}

export function setColorBanca() {
  const btnBanca = document.querySelectorAll('#PUBLICACAO')[4];
  if (btnBanca.style.border !== '2.5px solid') {
    resetMenu();
  }
  btnBanca.style.backgroundColor = '#000';
  btnBanca.style.color = '#fff';
  btnBanca.style.border = 'none';
}

export function setColorPremio() {
  const btnPremio = document.querySelectorAll('#PUBLICACAO')[5];
  if (btnPremio.style.border !== '2.5px solid') {
    resetMenu();
  }
  btnPremio.style.backgroundColor = '#000';
  btnPremio.style.color = '#fff';
  btnPremio.style.border = 'none';
}

export default function setBorderMenu(departamento) {
  const dep = document.querySelector(`#${departamento}`);
  const docentes = document.querySelector('#DOCENTES');
  docentes.style.backgroundColor = '#000';
  docentes.style.color = '#fff';
  docentes.style.border = 'none';
  document.querySelectorAll('a[id^=AU]').forEach((a) => {
    a.style.border = '1px solid';
  });

  dep.style.backgroundColor = departmentColors[departamento];
  if (departamento === 'AUH') {
    dep.style.color = '#000';
    dep.style.border = '1px solid #fff';
  } else {
    dep.style.color = '#fff';
  }

  document.querySelectorAll('#PUBLICACAO').forEach((a) => {
    a.style.border = '2.5px solid';
    a.style.backgroundColor = '';
    a.style.color = '';
  });
}

export function setFilterPerfilPB(departamento) {
  setBorderMenu(departamento);
  setColorProducaoB();
}

export function setFilterPerfilPA(departamento) {
  setBorderMenu(departamento);
  setColorProducaoA();
}

export function setFilterPerfilPT(departamento) {
  setBorderMenu(departamento);
  setColorProducaoT();
}

export function setFilterPerfilO(departamento) {
  setBorderMenu(departamento);
  setColorOrientacao();
}

export function setFilterPerfilBanca(departamento) {
  setBorderMenu(departamento);
  setColorBanca();
}

export function setFilterPerfilPremio(departamento) {
  setBorderMenu(departamento);
  setColorPremio();
}
