import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import LogoCNPQInvertido from '../assets/cnpq-logo-01.png';
import { getLastUpdateDate } from '../api';

function Footer() {
  const [lastUpdateDate, setLastUpdateDate] = useState(null);

  useEffect(() => {
    getLastUpdateDate().then((res) => {
      setLastUpdateDate(res);
    });
  }, []);

  return (
    <DivTeste>
      <DivFau>
        <b
          style={{
            display: 'block',
            marginTop: '3.5rem',
          }}
        >
          Última atualização:
          {' '}
          {lastUpdateDate}
        </b>
      </DivFau>

      <FooterFau>
        <List>
          <span style={{ fontWeight: 'bold' }}>Instituto de Matemática e Estatística</span>
          <span>R. do Matão, 1010 - Butantã, São Paulo - SP, 05508-090</span>
          <span>(11) 3091-6101</span>
        </List>
        <List>
          <span style={{ fontWeight: 'bold' }}>Faculdade de Arquitetura e Urbanismo</span>
          <span>R. do Lago, 876 - Butantã, São Paulo - SP, 05508-080</span>
          <span>(11) 3091-4795</span>
        </List>
        <List style={{ justifyContent: 'space-around' }}>
          <span style={{ fontWeight: 'bold' }}>Comissão de Pesquisa - CPq FAUUSP</span>
          <span>
            contato:
            <a style={{ textDecoration: 'none', color: '#000' }} href="mailto:cpqs-abertas@usp.br">cpqs-abertas@usp.br</a>
          </span>
          <span>(11) 3091-4534</span>
        </List>
        <List style={{ justifyContent: 'space-around' }}>
          <a href="http://www.cnpq.br/" target="_blank" rel="noopener noreferrer">
            <Image style={{ height: 41 }} src={LogoCNPQInvertido} alt="Logo CNPQ" />
          </a>
        </List>
      </FooterFau>
    </DivTeste>
  );
}

export default Footer;

const DivTeste = styled.div`
width: 100%;
grid-column-start:3;
grid-row-start:3;
`;

const FooterFau = styled.div`
grid-column-start: 2;
overflow: hidden;
width: 100%;
padding-top:30px;
display:flex;
justify-content: space-between;

`;

const DivFau = styled.div`
margin-bottom: 0;
text-align: right;
`;

const List = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
    > span {
        margin-bottom: 4px;
        text-align: center;
        font-size: 12px;
        font-family: sans-serif
    }
    > a {
        text-decoration: none;
        color: white;
        font-size: 20px;
    }
`;
const Image = styled.img`
    cursor: pointer;
`;
