import React from 'react';
import PropTypes from 'prop-types';
import { HorizontalBar } from 'react-chartjs-2';

function StackedBar(props) {
  const { data, type } = props;
  const options = {
    animation: {
      duration: 1500,
    },
    scales: {
      xAxes: [{
        stacked: true,
        gridLines: { display: true },
      }],
      yAxes: [{
        barPercentage: 0.8,
        stacked: true,
        gridLines: { display: false },
      }],
    },
    legend: {
      display: true,
      labels: {
        padding: 10,
        boxWidth: 20,
      },
    },
    maintainAspectRatio: true,
    responsive: false,
    tooltips: {
      mode: 'nearest',
    },
  };
  if (type === 'single') {
    options.scales.yAxes[0].barPercentage = 0.4;
  }

  return data && <HorizontalBar data={data} options={options} width={450} height={245} />;
}

StackedBar.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
};

StackedBar.defaultProps = {
  data: null,
  type: 'normal',
};

export default StackedBar;
