import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useParams, Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import {
  Close, Conteudo, DivCard, DivInfo, DivGraph, DivInfoText, DivInfoProf, DivLink,
} from '../styles';
import {
  getPessoa, getBancasCountTipos, getCountBancaPessoa, getTiposBancaPessoa,
  getDashboardCountByDepTipo, getCountBancaPessoaPorAno,
} from '../api';
import Loading from '../components/loading';
import Photo from '../components/photo';
import { setFilterPerfilBanca } from '../components/btnFunctions';
import BarChartDocenteProducao from '../components/chart-bar-docenteProd';
import LineChartDocenteProd from '../components/chart-line-docenteProd';

function PerfilBancas() {
  const [data, setData] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    getPessoa({ id }).then((responsePayload) => {
      setData(responsePayload);
    });
  }, []);

  if (!data) {
    return (
      <Conteudo
        style={{
          alignSelf: 'center',
          textAlign: 'center',
        }}
      >
        <Loading />
      </Conteudo>
    );
  }

  return <Perfil data={data} id={id} />;
}

function Perfil(props) {
  const { data, id } = props;
  const elements = [];
  const sorted = data.bancas.sort((a, b) => {
    return Number(b.ano) - Number(a.ano);
  });

  if (sorted.length === 0) {
    elements.push(
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: '20px 0px',
        }}
      >
        <span style={{ marginTop: -20 }}>
          <b>0 resultados</b>
        </span>
      </div>,
    );
  }

  const productionsByYear = {};
  sorted.forEach((production) => {
    if (productionsByYear[production.ano]) {
      productionsByYear[production.ano].push(production);
    } else {
      productionsByYear[production.ano] = [production];
    }
  });

  Object.keys(productionsByYear).forEach((year) => {
    productionsByYear[year].forEach((production) => {
      elements.push(
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            fontSize: 15,
            margin: '20px 0px',
          }}
        >
          <span>
            <b>Título:</b>
            {' '}
            {production.titulo}
          </span>
          <span>
            <b>Instituicao:</b>
            {' '}
            {production.instituicao}
          </span>
          <span>
            <b>Aluno</b>
            {' '}
            {production.aluno}
          </span>
          <span>
            <b>Tipo:</b>
            {' '}
            {production.tipo.trim().replace(/^\w/, (c) => {
              return c.toUpperCase();
            })}
          </span>
          <span>
            <b>País:</b>
            {' '}
            {production.pais}
          </span>
        </div>,
      );
    });

    elements.push(<span id="ano">{year}</span>);
  });

  setFilterPerfilBanca(data.departamento);
  return (
    <Conteudo style={{ justifyContent: 'start' }}>
      <DivCard>
        <DivInfo>
          <Photo id={id} />
          <DivInfoProf>
            <span style={{ fontSize: 25 }}>{data.nome_completo}</span>
            <span>Telefone</span>
            {
              data.contato
                ? (
                  <span>
                    +55 (11)
                    {data.contato}
                  </span>
                )
                : <span>-</span>
            }
            <span>Currículo Lattes</span>
            <a
              href={`http://lattes.cnpq.br/${data.id_lattes}`}
              target="_blank"
              rel="noreferrer"
            >
              http://lattes.cnpq.br/
              {data.id_lattes}
            </a>
          </DivInfoProf>
        </DivInfo>
        <DivGraph>
          <Carousel
            showArrows
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
            autoPlay
            infiniteLoop
            interval={5000}
          >
            <div>
              <BarChartDocenteProducao
                id={data.id_lattes}
                dep={data.departamento}
                getCountByDep={getBancasCountTipos}
                getCountDocente={getCountBancaPessoa}
                getTipos={getTiposBancaPessoa}
              />
            </div>
            <div>
              <LineChartDocenteProd
                dep={data.departamento}
                id={data.id_lattes}
                getDashboardCount={getDashboardCountByDepTipo}
                tipo="Bancas"
                getCountDocente={getCountBancaPessoaPorAno}
                getTipos={getTiposBancaPessoa}
              />
            </div>
          </Carousel>
        </DivGraph>
        <DivInfoText id="scroll">
          <span
            style={{
              fontSize: 25,
              fontWeight: 'bold',
              marginTop: -3.8,
            }}
          >
            Bancas
            <Link to={`/pessoa/perfil/${id}`} style={Close}>X</Link>
          </span>
          <DivLink>
            {elements.reverse().map((element) => {
              return (
                <div key={element}>
                  {element}
                </div>
              );
            })}
          </DivLink>
        </DivInfoText>
      </DivCard>
    </Conteudo>
  );
}

Perfil.propTypes = {
  data: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
};

export default PerfilBancas;
