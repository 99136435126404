import React from 'react';
import SolFau from '../assets/FAU-logo-01.png';
import './css/loading.scss';

function Loading() {
  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        height: 245,
        justifyContent: 'center',
      }}
    >
      <img
        style={{
          width: 100,
          height: 100,
        }}
        src={SolFau}
        className="loading-spinner-fau"
        alt="loading"
      />
      {' '}
    </div>
  );
}

export default Loading;
