import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'rc-slider/assets/index.css';
import styled from 'styled-components';
import LineChart from '../charts/line';
import Loading from './loading';

function LineChartDocente(props) {
  const {
    dep, id, getDashboardCount, getCountDocente,
  } = props;
  const [lineData, setLineData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const data = {};
    getDashboardCount({ ano_inicio: 1978, departamento: dep }).then((res) => {
      data[dep] = res[dep];
    }).then(() => {
      getCountDocente({ id }).then((resDoc) => {
        data.DOCENTE = resDoc.DOCENTE;
        setLineData(data);
        setIsLoading(false);
      });
    });
  }, []);

  return (
    <LineChartContainer>
      {
        isLoading
          ? <Loading />
          : <LineChart cachedData={lineData} changeLabels="docente" dep={dep} />
      }
    </LineChartContainer>
  );
}

LineChartDocente.propTypes = {
  dep: PropTypes.string.isRequired,
  getCountDocente: PropTypes.func.isRequired,
  getDashboardCount: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

const LineChartContainer = styled.div`
    padding: 20px;
    background-color: white;
`;

export default LineChartDocente;
