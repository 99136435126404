import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as S from '../styles';
import { DivLinkDocentes } from '../styles';
import { getNomesPessoa } from '../api';
import Loading from './loading';

function DocentesList(props) {
  const { department, setColorDocentes } = props;
  const [professors, setProfessors] = useState(null);

  useEffect(() => {
    setColorDocentes();
    getNomesPessoa().then((responsePayload) => {
      setProfessors(responsePayload);
    });
  }, []);

  if (!professors) {
    return (
      <S.CenteredColumn>
        <Loading />
      </S.CenteredColumn>
    );
  }

  return (
    <S.CenteredColumn style={{ justifyContent: 'start' }}>
      <DivLinkDocentes>
        {professors.map((professor) => {
          const { nome, departamento, id } = professor;
          let formattedId = id;
          if (id.length <= 15) {
            formattedId = `0${id}`;
          }

          const redirect = `/pessoa/perfil/${formattedId}`;
          if (department === 'all' || departamento === department) {
            return (
              <span key={id}>
                <Link to={redirect}>{nome}</Link>
              </span>
            );
          }

          return (
            <span
              key={id}
              style={{
                color: '#bbb',
                fontSize: '14px',
              }}
            >
              {nome}
            </span>
          );
        })}
      </DivLinkDocentes>
    </S.CenteredColumn>
  );
}

DocentesList.propTypes = {
  department: PropTypes.string.isRequired,
  setColorDocentes: PropTypes.func.isRequired,
};

export default DocentesList;
